import { partnerHostLive, partnerHostStaging } from '../../../../constants'
import React from 'react'
import loadable from '../../../loadable'
const Host = window.location.hostname
export const partnerRoutes = [
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token`,
    component: loadable(() => import('../../Driver/DriverApp/index')),
    isPrivate: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    exact: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/home-page`,
    component: loadable(() => import('../../Driver/DriverApp/User/HomePage')),
    isPrivate: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    exact: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/:bookingId/support`,
    component: loadable(() => import('../../Driver/DriverApp/User/Support')),
    isPrivate: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    exact: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/new-bookings`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/NewBooking')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/planned`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/Planned')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/completed`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/Completed')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/:token/:bookingId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/BookingDetails')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/chauffeurs/account`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Account/Chauffeurs')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/chauffeursOnly/account`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Account/ChauffeurOnly')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/chauffeur/add`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Account/EditChauffeur')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/chauffeur/edit/:chauffeurId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Account/EditChauffeur')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/cars/account`,
    component: loadable(() => import('../../Driver/DriverApp/Account/Cars')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/car/add`,
    component: loadable(() => import('../../Driver/DriverApp/Account/EditCar')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/car/edit/:carId`,
    component: loadable(() => import('../../Driver/DriverApp/Account/EditCar')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/settings/account`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Account/Settings')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/:token/settings/edit/:companyId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Account/EditCompany')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/chauffeur/:token/:bookingId`,
    component: loadable(() => import('../../Driver/DriverApp/Bookings/Assign')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/vehicle/:token/:bookingId`,
    component: loadable(() => import('../../Driver/DriverApp/Bookings/Assign')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/pickup/:token/:bookingId`,
    component: loadable(() => import('../../Driver/DriverApp/Bookings/Assign')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/confirm/:token/:bookingId`,
    component: loadable(() => import('../../Driver/DriverApp/Bookings/Assign')),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  // {
  //   path: `${
  //     Host === partnerHostLive || Host === partnerHostStaging
  //       ? '/'
  //       : '/partner/'
  //   }driver/app/:token/earnings/account`,
  //   component: loadable(() =>
  //     import('../../Driver/DriverApp/Account/Earnings')
  //   ),
  //   isPrivate: true,
  //   exact: true,
  //   allowedRoles: {
  //     company: ['company_partner'],
  //   },
  //   hideInSidebar: true,
  //   hideSidebar: true,
  //   showBottomMenu: true,
  // },
  // {
  //   path: `${
  //     Host === partnerHostLive || Host === partnerHostStaging
  //       ? '/'
  //       : '/partner/'
  //   }driver/app/:token/payouts/account`,
  //   component: loadable(() => import('../../Driver/DriverApp/Account/Payouts')),
  //   isPrivate: true,
  //   exact: true,
  //   allowedRoles: {
  //     company: ['company_partner'],
  //   },
  //   hideInSidebar: true,
  //   hideSidebar: true,
  //   showBottomMenu: true,
  // },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/chauffeurAgent/:token/:bookingId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/AssignCollectionAgent')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/vehicleAgent/:token/:bookingId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/AssignCollectionAgent')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/pickupAgent/:token/:bookingId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/AssignCollectionAgent')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
  {
    path: `${
      Host === partnerHostLive || Host === partnerHostStaging
        ? '/'
        : '/partner/'
    }driver/app/details/confirmAgent/:token/:bookingId`,
    component: loadable(() =>
      import('../../Driver/DriverApp/Bookings/AssignCollectionAgent')
    ),
    isPrivate: true,
    exact: true,
    allowedRoles: {
      company: ['company_partner'],
    },
    hideInSidebar: true,
    hideSidebar: true,
    showBottomMenu: true,
  },
]
