import React from 'react'
import loadable from '../../../loadable'

export const companyRoutes = [
  {
    isPrivate: true,
    path: '/home',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },

    component: loadable(() =>
      import('../../../../pages/App/CompanyPages/Home')
    ),
    title: 'Home',
    // iconActive: 'icons/home-icon-active-sidebar.svg',
    // iconInactive: 'icons/home-icon-inactive-sidebar.svg',
    iconActive: 'icons/icon-home-black.png',
    iconInactive: 'icons/icon-home-grey.png',
    showBottomMenu: true,
    hideSidebar: false,
    exact: true,
    homeRoute: true,
    // childRoutes: [
    //   {
    //     path: '/my-bookings/details/',
    //     allowedRoles: {
    //       company: ['company_admin', 'company_user'],
    //     },
    //     component: loadable(() =>
    //       import(
    //         '../../../../pages/App/CompanyPages/Booking/MyBookingDetails/MyBookingDetails'
    //       )
    //     ),
    //     isPrivate: true,
    //     exact: true,
    //     title: 'My Booking Details',
    //     hideInSidebar: true,
    //     hideSidebar: false,
    //   },
    // ]
  },

  {
    isPrivate: true,
    path: '/booking',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    childRoutes: [
      {
        path: '/main',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Booking')
        ),
        isPrivate: true,
        title: 'Bookings',
        iconActive: 'menu_bookings_active.png',
        iconInactive: 'menu_bookings_inactive.png',
        showBottomMenu: true,
        hideSidebar: false,
        tabs: [
          {
            title: 'New booking',
            path: '/new-booking',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Booking/NewBooking')
            ),
            isPrivate: true,
          },
          {
            title: 'My bookings',
            path: '/my-bookings',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Booking/MyBookings')
            ),
            isPrivate: true,
          },
          {
            title: 'Team bookings',
            path: '/team-bookings',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Booking/TeamBookings')
            ),
            isPrivate: true,
          },
        ],
      },
      {
        path: '/my-bookings/details/:bookingId',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import(
            '../../../../pages/App/CompanyPages/Booking/MyBookingDetails/MyBookingDetails'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'My Booking Details',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/team-bookings/details/:bookingId',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import(
            '../../../../pages/App/CompanyPages/Booking/TeamBookingsDetails/MyBookingDetails'
          )
        ),
        isPrivate: true,
        exact: true,
        title: 'Team Booking Details',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/new-booking/car-rental/:bookingId',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import(
            '../../../../pages/App/CompanyPages/Booking/NewBookingForm/steps/carRental/Confirm'
          )
        ),
        isPrivate: true,
        // exact: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/new-booking/airport-transfer-return',
        component: loadable(
          () =>
            import('../../../../pages/App/CompanyPages/Booking/NewBookingForm'),
          { chauffeurType: 'returnTransfer' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/new-booking/airport-transfer',
        component: loadable(
          () =>
            import('../../../../pages/App/CompanyPages/Booking/NewBookingForm'),
          { chauffeurType: 'transfer' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/new-booking/chauffeur',
        component: loadable(
          () =>
            import('../../../../pages/App/CompanyPages/Booking/NewBookingForm'),
          { chauffeurType: 'chauffeurService' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/new-booking/car-rental',
        component: loadable(
          () =>
            import('../../../../pages/App/CompanyPages/Booking/NewBookingForm'),
          { chauffeurType: 'carRental' }
        ),
        isPrivate: true,
        // exact:true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      // quotes booking routes start
      {
        path: '/quotes/airport-transfer',
        component: loadable(
          () => import('../../../../pages/App/CompanyPages/Booking/quotesForm'),
          { chauffeurType: 'transfer' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
        exact: true,
      },
      {
        path: '/quotes/chauffeur',
        component: loadable(
          () => import('../../../../pages/App/CompanyPages/Booking/quotesForm'),
          { chauffeurType: 'chauffeurService' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
        exact: true,
      },
      {
        path: '/quotes/car-rental',
        component: loadable(
          () => import('../../../../pages/App/CompanyPages/Booking/quotesForm'),
          { chauffeurType: 'carRental' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
        exact: true,
      },
      {
        path: '/quotes/car-rental/:messageId/:index',
        component: loadable(
          () =>
            import(
              '../../../../pages/App/CompanyPages/Booking/quotesForm/steps/QuoteByEmail'
            ),
          { chauffeurType: 'carRental' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/quotes/chauffeur/:messageId/:index',
        component: loadable(
          () =>
            import(
              '../../../../pages/App/CompanyPages/Booking/quotesForm/steps/QuoteByEmail'
            ),
          { chauffeurType: 'chauffeurService' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/quotes/airport-transfer/:messageId/:index',
        component: loadable(
          () =>
            import(
              '../../../../pages/App/CompanyPages/Booking/quotesForm/steps/QuoteByEmail'
            ),
          { chauffeurType: 'transfer' }
        ),
        isPrivate: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/support',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    hideInSidebar: true,
    childRoutes: [
      {
        path: '/',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Booking')
        ),
        hideInSidebar: true,
        isPrivate: true,
        title: 'Bookings',
        iconActive: 'menu_bookings_active.png',
        iconInactive: 'menu_bookings_inactive.png',
        showBottomMenu: true,
        hideSidebar: false,
        tabs: [
          {
            title: 'New booking',
            path: '/new-booking',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Booking/NewBooking')
            ),
            isPrivate: true,
          },
          {
            title: 'My bookings',
            path: '/my-bookings',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Booking/MyBookings')
            ),
            isPrivate: true,
          },
          {
            title: 'Team bookings',
            path: '/team-bookings',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Booking/TeamBookings')
            ),
            isPrivate: true,
          },
        ],
      },
    ],
  },
  {
    isPrivate: true,
    path: '/quotes',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    childRoutes: [
      {
        path: '/main',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Quotes')
        ),
        isPrivate: true,
        title: 'Quotes',
        iconActive: 'icon-quote-active.png',
        iconInactive: 'icon-quote-inactive.png',
        showBottomMenu: true,
        tabs: [
          {
            title: 'My quotes',
            path: '/my-quotes',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Quotes/MyQuotes')
            ),
            isPrivate: true,
          },
        ],
      },
    ],
  },
  {
    path: '/chat-client',
    component: loadable(() =>
      import('../../../../pages/App/CompanyPages/ChatClient/ChatClient')
    ),
    isPrivate: true,
    // title: 'Support',
    title: 'Support 24/7',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    hideInSidebar: true,
    hideSidebar: false,
    showBottomMenu: false,
  },
  {
    path: '/notifications-client',
    component: loadable(() =>
      import('../../../../pages/App/CompanyPages/NotificationsClient/index')
    ),
    isPrivate: true,
    title: 'Notifications',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    hideInSidebar: true,
    hideSidebar: false,
    showBottomMenu: false,
  },
  {
    path: '/more',
    component: loadable(() =>
      import('../../../../pages/App/CompanyPages/More/index')
    ),
    isPrivate: true,
    title: 'More',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    hideInSidebar: true,
    hideSidebar: false,
    showBottomMenu: true,
  },
  // {
  //   isPrivate: true,
  //   path: '/event',
  //   allowedRoles: {
  //     company: ['company_admin', 'company_user'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/main',
  //       component: loadable(() =>
  //         import('../../../../pages/App/CompanyPages/Events')
  //       ),
  //       isPrivate: true,
  //       title: 'Events',
  //       iconActive: 'event_star_icon_active.png',
  //       iconInactive: 'event_star_icon_inactive.png',
  //       showBottomMenu: true,
  //       hideSidebar: false,
  //       tabs: [
  //         {
  //           title: 'New event',
  //           path: '/new-event',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/NewEvent')
  //           ),
  //           isPrivate: true,
  //         },
  //         {
  //           title: 'My events',
  //           path: '/my-events',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/MyEvents')
  //           ),
  //           isPrivate: true,
  //         },
  //       ],
  //     },
  //     {
  //       path: '/my-event/details/:eventId',
  //       component: loadable(() =>
  //         import('../../../../pages/App/CompanyPages/Events/MyEventDetails')
  //       ),
  //       allowedRoles: {
  //         company: ['company_admin', 'company_user'],
  //       },
  //       isPrivate: true,
  //       title: 'My Event Details',
  //       hideInSidebar: true,
  //       hideSidebar: true,
  //       tabs: [
  //         // Define child routes here
  //         {
  //           path: '/service',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/Tabs/Service')
  //           ),
  //           isPrivate: true,
  //           title: 'Service',
  //           exact: true,
  //         },
  //         {
  //           path: '/comments',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/Tabs/Comments')
  //           ),
  //           isPrivate: true,
  //           hideInSidebar: true,
  //           hideSidebar: true,
  //           title: 'Comments',
  //         },
  //         {
  //           path: '/coordinates',
  //           component: loadable(() =>
  //             import(
  //               '../../../../pages/App/CompanyPages/Events/Tabs/Coordinates'
  //             )
  //           ),
  //           isPrivate: true,
  //           hideInSidebar: true,
  //           hideSidebar: true,
  //           title: 'Coordinates',
  //         },
  //         {
  //           path: '/pricing',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/Tabs/Pricing')
  //           ),
  //           isPrivate: true,
  //           hideInSidebar: true,
  //           hideSidebar: true,
  //           title: 'Pricing',
  //         },

  //         {
  //           path: '/summary',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/Tabs/Summary')
  //           ),
  //           isPrivate: true,
  //           hideInSidebar: true,
  //           hideSidebar: true,
  //           title: 'Summary',
  //         },
  //         {
  //           path: '/map',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/Tabs/Map')
  //           ),
  //           isPrivate: true,
  //           hideInSidebar: true,
  //           hideSidebar: true,
  //           title: 'Map',
  //         },
  //         {
  //           path: '/settings',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Events/Tabs/Settings')
  //           ),
  //           isPrivate: true,
  //           hideInSidebar: true,
  //           hideSidebar: true,
  //           title: 'Settings',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    isPrivate: true,
    path: '/payments',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    childRoutes: [
      {
        path: '/main',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Payments')
        ),
        isPrivate: true,
        title: 'Payments',
        iconActive: 'menu_payments_active.png',
        iconInactive: 'menu_payments_inactive.png',
        showBottomMenu: true,
        tabs: [
          {
            title: 'Invoices',
            path: '/invoices',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Invoices')
            ),
            isPrivate: true,
          },
          {
            title: 'Payment method',
            path: '/method',
            component: loadable(() =>
              import(
                '../../../../pages/App/CompanyPages/Payments/PaymentMethod'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Commissions',
            path: '/commissions',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Commissions')
            ),
            isPrivate: true,
          },
          {
            title: 'Payouts',
            path: '/payouts',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Payout')
            ),
            isPrivate: true,
          },
          // {
          //   title: 'Settings',
          //   path: '/settings',
          //   component: loadable(() =>
          //     import('../../../../pages/App/CompanyPages/Payments/Settings')
          //   ),
          //   isPrivate: true,
          // },
        ],
      },
      {
        path: '/mainMobile',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Payments')
        ),
        title: 'Currency',
        isPrivate: true,
        showBottomMenu: true,
        hideInSidebar: true,
        hideSidebar: false,
        childRoutes: [
          {
            title: 'Invoices',
            path: '/invoices',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Invoices')
            ),
            isPrivate: true,
            showBottomMenu: true,
          },
          {
            title: 'Payment method',
            path: '/method',
            component: loadable(() =>
              import(
                '../../../../pages/App/CompanyPages/Payments/PaymentMethod'
              )
            ),
            isPrivate: true,
          },
          {
            title: 'Commissions',
            path: '/commissions',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Commissions')
            ),
            isPrivate: true,
          },
          {
            title: 'Payouts',
            path: '/payouts',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Payout')
            ),
            isPrivate: true,
          },
          {
            title: 'Settings',
            path: '/settings',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Payments/Settings')
            ),
            isPrivate: true,
          },
        ],
      },
      {
        path: '/add-payment-method',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Payments/AddPaymentMethod')
        ),
        isPrivate: true,
        title: 'Add payment method',
        hideInSidebar: true,
        hideSidebar: false,
      },
    ],
  },
  {
    isPrivate: true,
    path: '/settings',
    allowedRoles: {
      company: ['company_admin', 'company_user'],
    },
    childRoutes: [
      {
        path: '/main',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings')
        ),
        isPrivate: true,
        title: 'Account',
        iconActive: 'menu_account_active.png',
        iconInactive: 'menu_account_inactive.png',
        showBottomMenu: false,
        tabs: [
          {
            title: 'Settings',
            path: '/general',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Settings/Profiles')
            ),
            isPrivate: true,
          },
          {
            title: 'ROLZO First',
            path: '/rolzoFirst',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Settings/RolzoFirst')
            ),
            isPrivate: true,
          },
          {
            title: 'Team',
            path: '/users',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Settings/Users')
            ),
            isPrivate: true,
          },
          {
            title: 'Clients',
            path: '/clients',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Settings/Clients')
            ),
            isPrivate: true,
          },
          {
            title: 'Devices',
            path: '/devices',
            component: loadable(() =>
              import('../../../../pages/App/CompanyPages/Settings/Devices')
            ),
            isPrivate: true,
          },
        ],
      },
      {
        path: '/notifications',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/notifications')
        ),
        title: 'Notifications',
        isPrivate: true,
        exact: true,
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/company-profile',
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/CompanyProfile')
        ),
        isPrivate: true,
        title: 'Profile',
        allowedRoles: {
          company: ['company_admin'],
        },
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/my-profile',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/MyProfile')
        ),
        isPrivate: true,
        exact: true,
        title: 'My profile',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/billing',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Account/Billing/index')
        ),
        isPrivate: true,
        exact: true,
        title: 'Billing',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/preferences',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Account/Preferences')
        ),
        isPrivate: true,
        exact: true,
        title: 'Preferences',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/change-password',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/ChangePassword')
        ),
        isPrivate: true,
        title: 'Set a new password',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/invite-new-users',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/InviteNewUsers')
        ),
        isPrivate: true,
        title: 'Invite new members',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/edit-client/user/:userId/client/:clientId',
        allowedRoles: {
          company: ['company_admin', 'company_user'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/EditClient')
        ),
        isPrivate: true,
        title: 'Edit client',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/edit-user/:companyId/user/:userId',
        allowedRoles: {
          company: ['company_admin'],
        },
        component: loadable(() =>
          import('../../../../pages/App/CompanyPages/Settings/CompanyUser')
        ),
        isPrivate: true,
        title: 'Edit company user',
        hideInSidebar: true,
        hideSidebar: false,
      },
      {
        path: '/multiple-invite-users',
        allowedRoles: {
          company: ['company_admin'],
        },
        component: loadable(() =>
          import(
            '../../../../pages/App/CompanyPages/Settings/MultipleInviteNewUsers'
          )
        ),
        isPrivate: true,
        title: 'Invite new members',
        hideInSidebar: true,
        hideSidebar: false,
      },
    ],
  },
  // {
  //   isPrivate: true,
  //   path: '/referrals',
  //   allowedRoles: {
  //     company: ['company_admin', 'company_user'],
  //   },
  //   childRoutes: [
  //     {
  //       path: '/main',
  //       component: loadable(() =>
  //         import('../../../../pages/App/CompanyPages/Referrals')
  //       ),
  //       isPrivate: true,
  //       title: 'Earn $50',
  //       iconActive: 'referral_active.png',
  //       iconInactive: 'referral_inactive.png',
  //       showBottomMenu: true,
  //       tabs: [
  //         {
  //           title: 'About',
  //           path: '/about',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Referrals/About')
  //           ),
  //           isPrivate: true,
  //         },
  //         {
  //           title: 'Earned credits',
  //           path: '/credits',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Referrals/Credits')
  //           ),
  //           isPrivate: true,
  //         },
  //         {
  //           title: 'Referees',
  //           path: '/referee',
  //           component: loadable(() =>
  //             import('../../../../pages/App/CompanyPages/Referrals/Referees')
  //           ),
  //           isPrivate: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
]
