import {
  OPERATIONS,
  NETWORK,
  FLEET,
  RATE,
  FINANCE,
  ACCOUNT,
  PAYOUT,
  ANALYTICS,
  AUTOMATION,
} from '../actions'

//load tabs state from local storage
const getTabs = localStorage.getItem('tabs')
const INITIAL_STATE = getTabs
  ? JSON.parse(getTabs)
  : {
      operations: false,
      network: false,
      fleet: false,
      rates: false,
      finance: false,
      account: false,
      payouts: false,
      automation: false,
      analytics: false,
    }

export default (state = INITIAL_STATE, action) => {
  //store new state in localStorage (to retain the new state after refreshing the page)
  switch (action.type) {
    case OPERATIONS:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, operations: !state.operations })
      )
      return {
        ...state,
        operations: !state.operations,
      }
    case NETWORK:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, network: !state.network })
      )
      return {
        ...state,
        network: !state.network,
      }
    case FLEET:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, fleet: !state.fleet })
      )
      return {
        ...state,
        fleet: !state.fleet,
      }
    case RATE:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, rates: !state.rates })
      )
      return {
        ...state,
        rates: !state.rates,
      }
    case FINANCE:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, finance: !state.finance })
      )
      return {
        ...state,
        finance: !state.finance,
      }
    case ACCOUNT:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, account: !state.account })
      )
      return {
        ...state,
        account: !state.account,
      }
    case PAYOUT:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, payouts: !state.payouts })
      )
      return {
        ...state,
        payouts: !state.payouts,
      }
    case ANALYTICS:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, analytics: !state.analytics })
      )
      return {
        ...state,
        analytics: !state.analytics,
      }
    case AUTOMATION:
      localStorage.setItem(
        'tabs',
        JSON.stringify({ ...state, automation: !state.automation })
      )
      return {
        ...state,
        automation: !state.automation,
      }
    default:
      return state
  }
}
