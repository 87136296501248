import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import indexRoutes from './router'
import { Route, Switch, withRouter } from 'react-router'
import {
  checkAuth,
  fetchNotificationsList,
  fetchOperationAlertCount,
  fetchBookingPendingCount,
  fetchUnreadChatsByAdminCount,
  fetchSentByAdminQuotesCount,
  fcmNotification,
  fcmTokenUpdate,
  checkDevices,
  setUser,
  isTokenValid,
  sendSupportLogEmail,
  emptyIsLateRequestState,
  fetchPartnerCount,
  logger,
} from './actions'
import { getIpLocation, updateLastSeen } from './actions/auth'
import PageLoading from './components/PageLoading'
import { GoogleApiWrapper } from 'google-maps-react'
import { UAParser } from 'ua-parser-js'
import _ from 'lodash'

const aes256 = require('aes256')
const key = 'my passphrase'
const storedUserKey = 'RolzoCurrentUser'

function importElectron() {
  const electron = window.require('electron')
  const {
    START_NOTIFICATION_SERVICE,
    NOTIFICATION_SERVICE_STARTED,
    NOTIFICATION_SERVICE_ERROR,
    NOTIFICATION_RECEIVED,
    TOKEN_UPDATED,
  } = window.require('electron-push-receiver/src/constants')
  return {
    electron,
    START_NOTIFICATION_SERVICE,
    NOTIFICATION_SERVICE_STARTED,
    NOTIFICATION_SERVICE_ERROR,
    NOTIFICATION_RECEIVED,
    TOKEN_UPDATED,
  }
}
function App({
  checkAuth,
  isLoggedIn,
  userId,
  authToken,
  isMasqueradeUser,
  fetchNotificationsList,
  fetchOperationAlertCount,
  fetchBookingPendingCount,
  fetchUnreadChatsByAdminCount,
  fetchSentByAdminQuotesCount,
  fcmNotification,
  fcmTokenUpdate,
  checkDevices,
  getIpLocation,
  setUser,
  isTokenValid,
  sendSupportLogEmail,
  emptyIsLateRequestState,
  logger,
  history,
  location,
  updateLastSeen,
  partnerToken,
  fetchPartnerCount,
  companyId,
  isCompanyAdmin,
  ...prop
}) {
  const [initialized, setInitialized] = useState(false)

  const handleClick = async () => {
    if (userId) {
      try {
        await updateLastSeen(userId)
      } catch (error) {
        logger({ fileName: 'app.jsx', error: error })
        console.log(error)
      }
    }
  }

  const checkDevice = async (userId, agent) => {
    try {
      const device = await checkDevices(userId, agent)
      return device.data
    } catch (err) {
      logger({ fileName: 'app.jsx', error: err })
      console.log(err)
    }
  }
  const checkAuthValid = async data => {
    const response = await isTokenValid(data.authToken)
    if (response && response.data) {
      if (response.data.hasOwnProperty('tokenValid')) {
        if (response.data.tokenValid === true) {
          setUser(data)
        }
        if (response.data.tokenValid === false) {
          setUser()
        }
      }
    }
  }
  useEffect(() => {
    if (location && location.pathname) {
      var arr = location.pathname.split('/')
      const hasValue = _.find(arr, function(o) {
        return o === 'chat-client'
      })
      if (!hasValue) {
        if (location.search !== '?show_chat=1') {
          emptyIsLateRequestState()
        }
      }
    }
  }, [location, emptyIsLateRequestState])
  useEffect(() => {
    checkAuth(async () => {
      var search = location.search
      var data = search.split('&')
      await getIpLocation()
      if (data) {
        var decoded = null
        data.forEach(element => {
          if (element.search('localtoken') !== -1) {
            decoded = element.split('=')[1]
          }
        })
        if (decoded) {
          if (JSON.parse(decodeURIComponent(decoded)) !== '0') {
            var uri_dec = JSON.parse(decodeURIComponent(decoded))
            var decrypted = aes256.decrypt(key, uri_dec)
            await checkAuthValid(JSON.parse(decrypted))
          }
        }
      }
      var timer = null
      if (isLoggedIn && userId && authToken) {
        if (isMasqueradeUser) {
          setInitialized(true)
          fetchNotificationsList()
          setTimeout(() => {
            fetchOperationAlertCount() //
            fetchBookingPendingCount() //
            fetchUnreadChatsByAdminCount() //
            fetchSentByAdminQuotesCount() //
            fetchPartnerCount() //
          }, 1500)
        } else {
          const devices = await checkDevice(userId, navigator.userAgent)
          if (devices && devices.active) {
            let parser = new UAParser()
            parser.setUA(devices.agent)
            const result = parser.getOS()
            const device = parser.getDevice()
            sendSupportLogEmail({
              type: 'login',
              data: {
                Device: `${result.name} ${
                  device.vendor ? `- ${device.vendor}` : ''
                }`,
              },
            })
            setInitialized(true)
            fetchNotificationsList()
            // commented below fow testing counts issue fix
            // fetchOperationAlertCount()//
            // fetchBookingPendingCount()//
            // fetchUnreadChatsByAdminCount()//
            // fetchSentByAdminQuotesCount()//
            // fetchPartnerCount()//
          } else {
            if (!window.ReactNativeWebView) {
              setUser()
              setInitialized(true)
            } else {
              setInitialized(true)
              fetchNotificationsList()
            }
            // fetchNotificationsList()
          }
        }
        try {
          await updateLastSeen(userId)
          timer = setInterval(() => {
            handleClick()
            // fetchOperationAlertCount()//
            // fetchBookingPendingCount()//
            // fetchUnreadChatsByAdminCount()//
            // fetchSentByAdminQuotesCount()//
            // fetchPartnerCount()//
          }, 300000)
        } catch (error) {
          logger({ fileName: 'app.jsx', error: error })
          console.log(error)
        }
      } else {
        if (timer) {
          clearInterval(timer)
        }
        setInitialized(true)
      }
    })
  }, [
    checkAuth,
    isLoggedIn,
    userId,
    authToken,
    isMasqueradeUser,
    window,
    fetchNotificationsList,
    fetchOperationAlertCount,
    fetchBookingPendingCount,
    fetchUnreadChatsByAdminCount,
    fetchSentByAdminQuotesCount,
    partnerToken,
    fetchPartnerCount,
    getIpLocation,
  ])

  const registerFcm = async (token, authToken, userId) => {
    if (authToken && !window.ReactNativeWebView) {
      let data = {
        authToken: authToken,
        token: token,
        userId: userId,
        agent: navigator.userAgent,
      }
      try {
        await fcmNotification(data)
      } catch (error) {
        logger({ fileName: 'app.jsx', error: error })
        console.log(error)
      }
    }
  }

  const updateFcmToken = async (token, authToken, userId) => {
    if (authToken && token) {
      let data = {
        authToken: authToken,
        token: token,
        userId: userId,
        agent: navigator.userAgent,
      }
      try {
        await fcmTokenUpdate(data)
      } catch (error) {
        logger({ fileName: 'app.jsx', error: error })
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (window && window.require) {
      const importElecton = importElectron()
      // Module to create native browser window.
      const ipcRenderer = importElecton.electron.ipcRenderer

      const senderId = '1021255257590'
      if (isLoggedIn && userId && authToken) {
        checkAuth(() => {
          ipcRenderer.send(importElecton.START_NOTIFICATION_SERVICE, senderId)
        })
      }
      // Listen for service successfully started
      ipcRenderer.on(importElecton.NOTIFICATION_SERVICE_STARTED, (_, token) => {
        checkAuth(() => {
          registerFcm(token, authToken, userId)
        })
      })
      // Handle notification errors
      ipcRenderer.on(importElecton.NOTIFICATION_SERVICE_ERROR, (_, error) => {
        console.log('step 3 notification error')
      })

      // Send FCM token to backend
      ipcRenderer.on(importElecton.TOKEN_UPDATED, (_, token) => {
        checkAuth(() => {
          updateFcmToken(token, authToken, userId)
        })
      })

      // Display notification
      ipcRenderer.on(
        importElecton.NOTIFICATION_RECEIVED,
        (_, serverNotificationPayload) => {
          // check to see if payload contains a body string, if it doesn't consider it a silent push
          if (serverNotificationPayload.notification.body) {
            // payload has a body, so show it to the user
            // const bodayData = serverNotificationPayload.data
            // const title = serverNotificationPayload.notification.title
            // let myNotification = new Notification(title, {
            //   body: serverNotificationPayload.notification.body,
            //   // icon: '/images/icons/chat/rolzo_avatar.png',
            // })
            // myNotification.onclick = () => {
            //   if (title === 'New Notification') {
            //     // notification
            //     history.push('/booking/main/new-booking/?show_notifications=1')
            //   } else {
            //     if (bodayData.bookingId) {
            //       if (bodayData.isAdmin) {
            //         history.push(
            //           `${ bodayData.desktopLink.split('com')[1] } /? show_events = 1`
            //         )
            //       }
            //     } else {
            //       history.push(`/ booking / main / new- booking /? show_chat = 1`)
            //     }
            //   }
            // }
          } else {
            // payload has no body, so consider it silent (and just consider the data portion)
            console.log(
              'do something with the key/value pairs in the data',
              serverNotificationPayload.data
            )
          }
        }
      )
    }
    if (isLoggedIn && authToken) {
      registerFcm(null, authToken, userId)
      if (userId && window) {
        window.parent.postMessage(
          {
            action: 'loginCompleted',
            data: { authToken: authToken, userId: userId },
          },
          '*'
        )
        const storedUser = localStorage.getItem(storedUserKey)
        const data = JSON.parse(storedUser)
        var encrypted = aes256.encrypt(key, JSON.stringify(data))
        window.parent.postMessage(
          {
            action: 'updateLocalStorage',
            data: encrypted,
          },
          '*'
        )
      }
    }
    if (isLoggedIn) {
      setTimeout(() => {
        fetchOperationAlertCount()
        fetchBookingPendingCount()
        fetchUnreadChatsByAdminCount()
        fetchSentByAdminQuotesCount()
        fetchPartnerCount()
      }, 1500)

      // fetchNotificationsList()
    }
  }, [
    history,
    isLoggedIn,
    userId,
    authToken,
    fetchNotificationsList,
    fetchOperationAlertCount,
    fetchBookingPendingCount,
    fetchUnreadChatsByAdminCount,
    fetchSentByAdminQuotesCount,
    fetchPartnerCount,
  ])

  if (!initialized) {
    return <PageLoading />
  }

  return (
    <Switch>
      {indexRoutes.map((prop, key) => {
        return (
          <Route
            path={prop.path}
            exact={!!prop.exact}
            component={prop.component}
            key={key}
          />
        )
      })}
    </Switch>
  )
}

const mapStateToProps = ({
  auth: {
    isLoggedIn,
    userId,
    authToken,
    isMasqueradeUser,
    companyId,
    isCompanyAdmin,
  },
  appWebsocket: { connected },
}) => ({
  isLoggedIn,
  userId,
  authToken,
  isMasqueradeUser,
  isWebSocketConnected: connected,
  companyId: companyId,
  isCompanyAdmin: isCompanyAdmin,
})

const LoadingContainer = props => <div />

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD_7NG2i0XhBMScTh30xxoJouQe1lnvb68',
  language: 'en',
  LoadingContainer: LoadingContainer,
})(
  withRouter(
    connect(mapStateToProps, {
      checkAuth,
      fetchNotificationsList,
      fetchOperationAlertCount,
      fetchBookingPendingCount,
      fetchUnreadChatsByAdminCount,
      fetchSentByAdminQuotesCount,
      fcmNotification,
      fcmTokenUpdate,
      checkDevices,
      setUser,
      isTokenValid,
      sendSupportLogEmail,
      emptyIsLateRequestState,
      updateLastSeen,
      getIpLocation,
      fetchPartnerCount,
      logger,
    })(App)
  )
)
