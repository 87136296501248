import {
  OPERATIONS,
  NETWORK,
  FLEET,
  RATE,
  FINANCE,
  ACCOUNT,
  PAYOUT,
  ANALYTICS,
  AUTOMATION,
} from './types'

export const operationsTab = () => dispatch => {
  dispatch({
    type: OPERATIONS,
  })
}
export const networkTab = () => dispatch => {
  dispatch({
    type: NETWORK,
  })
}
export const fleetTab = () => dispatch => {
  dispatch({
    type: FLEET,
  })
}
export const ratesTab = () => dispatch => {
  dispatch({
    type: RATE,
  })
}
export const financeTab = () => dispatch => {
  dispatch({
    type: FINANCE,
  })
}
export const automationTab = () => dispatch => {
  dispatch({
    type: AUTOMATION,
  })
}
export const accountTab = () => dispatch => {
  dispatch({
    type: ACCOUNT,
  })
}
export const payoutTab = () => dispatch => {
  dispatch({
    type: PAYOUT,
  })
}
export const analyticsTab = () => dispatch => {
  dispatch({
    type: ANALYTICS,
  })
}
