import { createOrEditItem, deleteItem } from './form'
import { globalRateFormData } from '../models/globalRate'
import {
  globalRateLocationFormData,
  globalRateLocationRatesFormDataClone,
  globalRateLocationRatesFormDataDefault,
} from '../models/globalRateLocations'
import { handleActionError } from './helpers'
import { connectedApiService } from '..'
import _ from 'lodash'
import { logger } from './logger'
export const createGlobalRate = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'global-rate',
      formatFormData: globalRateFormData,
      successMessage: 'Global rate was successfully created',
    })
  )

export const editGlobalRate = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `global-rate/${id}`,
      formatFormData: globalRateFormData,
      successMessage: 'Global rate was successfully updated',
    })
  )

export const toggleGlobalRateStatus = (id, active = false) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        active,
      },
      {
        isEdit: true,
        endpoint: `global-rate/${id}`,
        successMessage: 'Global rate was successfully updated',
      }
    )
  )

export const createGlobalRateLocation = (values, rateId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `global-rate/${rateId}/location`,
      formatFormData: globalRateLocationFormData,
      successMessage: 'Location was successfully added',
    })
  )

export const editGlobalRateLocationRateType = (
  values,
  rateId,
  locationId,
  rateType,
  formDataFormatter
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `global-rate/${rateId}/location/${locationId}/rate-type/${_.kebabCase(
        rateType
      )}`,
      formatFormData:
        formDataFormatter || globalRateLocationRatesFormDataDefault,
      successMessage: 'Location was successfully updated',
    })
  )
}
export const editGlobalRateCardRateType = (
  values,
  rateId,
  locationId,
  cardId,
  rateType,
  formDataFormatter
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `global-rate/${rateId}/location/${locationId}/card/${cardId}/rate-type/${_.kebabCase(
        rateType
      )}`,
      formatFormData:
        formDataFormatter || globalRateLocationRatesFormDataDefault,
      successMessage: 'Card was successfully updated',
    })
  )
}

// export const updateABrateItem = (id, locationId, values) => async dispatch => {
//   await dispatch(
//     createOrEditItem(values, {
//       isEdit: true,
//       editRequest: 'put',
//       endpoint: `global-rate/editItem/${id}/location/${locationId}`,
//       successMessage:
//         'Avg Distance durations successfully updated to respective A-B Rates',
//     })
//   )
// }

export const updateABrateItem = (
  rateId,
  locationId,
  values
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `global-rate/rateId/${rateId}/location/${locationId}`,
      successMessage:
        'Avg Distance durations successfully updated to respective A-B Rates',
    })
  )
}
export const updateAbMatrix = values => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `updateDistanceDuration`,
      successMessage:
        'Avg Distance durations successfully updated for all same A-B Rates',
    })
  )
}

export const updateSmartRates = values => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `smartRateScript`,
      successMessage: 'Smart rate was successfully updated',
    })
  )
}

export const smartRateUpdateInProgresss = (
  rateId,
  locationId
) => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `smartRateUpdateInProgresss/rateId/${rateId}/location/${locationId}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}
export const smartRateUpdateRules = (rateId, locationId) => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `smartRateUpdateRules/rateId/${rateId}/location/${locationId}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}

export const deleteABItems = (rateId, locationId, list) => async dispatch => {
  try {
    return (
      (await connectedApiService.delete(
        `global-rate/rateId/${rateId}/location/${locationId}`,
        list
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}

export const updateRadiusEligibility = (
  values,
  locationId,
  rateType,
  mode
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `global-rate/locationId/${locationId}/rate-type/${_.kebabCase(
        rateType
      )}/mode/${mode}`,
      successMessage:
        'Radius Eligibility successfully updated for this location',
    })
  )
}
export const updateSmartRateAlgoMethod = (
  values,
  locationId,
  rateType,
  mode
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `global-rate/locationId/${locationId}/rate-type/${_.kebabCase(
        rateType
      )}/mode/${mode}`,
      successMessage: `Smart rate Algo successfully updated to ${
        values.smartRateType === 'withAb'
          ? "based on AB's"
          : 'based on hourly rates'
      }`,
    })
  )
}

export const cloneGlobalRateLocationRateType = (
  values,
  rateId,
  locationId,
  rateType,
  formDataFormatter
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'put',
      endpoint: `global-rate/${rateId}/location/${locationId}/rate-type/${_.kebabCase(
        rateType
      )}`,
      formatFormData: formDataFormatter || globalRateLocationRatesFormDataClone,
      successMessage: 'Location was successfully updated',
    })
  )
}

export const toggleLocationStatus = (
  rateId,
  locationId,
  active = false
) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        active,
      },
      {
        isEdit: true,
        endpoint: `global-rate/${rateId}/location/${locationId}`,
        successMessage: 'Location was successfully updated',
      }
    )
  )

export const UpdateLocation = (rateId, locationId, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `global-rate/${rateId}/location/${locationId}`,
      formatFormData: globalRateLocationFormData,
      successMessage: 'Location was successfully updated',
    })
  )

export const getGlobalRatesCloneList = values => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `global-Clone-list/${values.city}/${values.mode}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}

export const getCloneLocationRates = (
  globalRateId,
  LocationId
) => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `global-rate/${globalRateId}/location/${LocationId}`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}

export const getCloneSmartLocationRates = (
  globalRateId,
  LocationId
) => async dispatch => {
  try {
    return (
      (await connectedApiService.get(
        `global-rate/${globalRateId}/location/${LocationId}/rate-type/smartRates`
      )) || {}
    )
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}

export const deleteLocationRates = (
  globalRateId,
  locationId
) => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `global-rate/${globalRateId}/location/${locationId}`,
      successMessage: 'Location was successfully deleted',
    })
  )

export const getCurrencyMapping = () => async dispatch => {
  try {
    return (await connectedApiService.get(`currency-Map`)) || {}
  } catch (error) {
    logger({ fileName: 'rates', error: error })
    return handleActionError(error)
  }
}

export const cloneToVEhicle = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'cloneToVehicle',
      successMessage: 'Vehicle clone was successfully done',
    })
  )

export const rateUpdateRDG = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rateUpdateRDG',
      successMessage: 'Rate updated successfully.',
    })
  )

export const rateUpdateRDGArray = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rateUpdateRDGArray',
      successMessage: 'Rate updated successfully.',
    })
  )

export const rateUpdateRDGThirty = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rateUpdateRDGThirty',
      successMessage: 'Rate updated successfully.',
    })
  )

export const rateUpdateRDGArrayThirty = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rateUpdateRDGArrayThirty',
      successMessage: 'Rate updated successfully.',
    })
  )

export const rateUpdateRDGSixty = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rateUpdateRDGSixty',
      successMessage: 'Rate updated successfully.',
    })
  )

export const rateUpdateRDGArraySixty = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'rateUpdateRDGArraySixty',
      successMessage: 'Rate updated successfully.',
    })
  )

export const createVipRate = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'vip-rates',
      successMessage: 'Rate was successfully created',
    })
  )

export const createTrainVipRate = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'vip-rates-train',
      successMessage: 'Rate was successfully created',
    })
  )

export const editVipRate = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `vip-rates/${id}`,
      successMessage: 'Rate was successfully updated',
    })
  )

export const deleteVipRate = rateId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `vip-rates/${rateId}`,
      successMessage: 'Rate was successfully deleted',
    })
  )

export const calculateBenefits = (benefits, rate, currency) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        benefits,
        rate,
        currency,
      },
      {
        endpoint: `vip-rates/benefits`,
      }
    )
  )

export const deleteBlocker = (globalRateId, locationId) => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `smartRatesBlockers/${globalRateId}/location/${locationId}`,
      successMessage: 'Smart Rate Blocker Sucessfully Removed',
    })
  )

export const getLocationItems = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `getLocationItems`,
      successMessage: 'Locations successfully fetched',
    })
  )
export const updateLocationItem = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `updateLocationItem`,
      successMessage: 'Location successfully updated',
    })
  )
export const updateAllLocationItem = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `updateAllLocationItems`,
      successMessage: 'All locations successfully updated',
    })
  )

export const createGlobalRateCard = (
  values,
  locationId,
  mode
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `global-rate/locationId/${locationId}/rate-card/mode/${mode}`,
      successMessage: 'Rate card created successfully',
    })
  )
}

export const editGlobalRateCard = (
  values,
  locationId,
  mode
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `global-rate/locationId/${locationId}/rate-card/mode/${mode}`,
      successMessage: 'Rate card successfully updated',
    })
  )
}

export const cloneGlobalRateCard = (
  values,
  locationId,
  mode
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `global-rate/locationId/${locationId}/rate-card-clone/mode/${mode}`,
      successMessage: 'Rate card created successfully',
    })
  )
}
export const updateStatusGlobalRateCard = (
  values,
  cardId
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `global-rate/rate-card/${cardId}/status`,
      successMessage: `Rate card successfully ${
        values.status ? 'activated' : 'deactivated'
      }`,
    })
  )
}
export const createMeetAssistRateCard = (values, rateId) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/rate-card/${rateId}`,
      successMessage: 'Rate card created successfully',
    })
  )
}

export const editMeetAssistRateCard = (values, rateId) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `vip-rates/${rateId}/rate-card`,
      successMessage: 'Rate card successfully updated',
    })
  )
}

export const cloneMeetAssistRateCard = (values, rateId) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/rate-card-clone/${rateId}`,
      successMessage: 'Rate card created successfully',
    })
  )
}

export const updateStatusMeetAssistRateCard = (
  values,
  cardId
) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `vip-rates/rate-card/${cardId}/status`,
      successMessage: `Rate card successfully ${
        values.status ? 'activated' : 'deactivated'
      }`,
    })
  )
}
