import moment from 'moment'
import { getRoundedTime } from '../helpers'
import _ from 'lodash'

export const defaultCurrenciesFour = [
  { label: 'GBP', value: 'currency_gbp' },
  { label: 'EUR', value: 'currency_eur' },
  { label: 'USD', value: 'currency_usd' },
  { label: 'AED', value: 'currency_aed' },
  { label: 'CAD', value: 'currency_cad' },
]
export const defaultCurrencies = [
  { label: 'GBP', value: 'currency_gbp' },
  { label: 'EUR', value: 'currency_eur' },
  { label: 'USD', value: 'currency_usd' },
  { label: 'AED', value: 'currency_aed' },
  { label: 'AFN', value: 'currency_afn' },
  { label: 'ALL', value: 'currency_all' },
  { label: 'AMD', value: 'currency_amd' },
  { label: 'ANG', value: 'currency_ang' },
  { label: 'AOA', value: 'currency_aoa' },
  { label: 'ARS', value: 'currency_ars' },
  { label: 'AUD', value: 'currency_aud' },
  { label: 'AWG', value: 'currency_awg' },
  { label: 'AZN', value: 'currency_azn' },
  { label: 'BAM', value: 'currency_bam' },
  { label: 'BBD', value: 'currency_bbd' },
  { label: 'BDT', value: 'currency_bdt' },
  { label: 'BGN', value: 'currency_bgn' },
  { label: 'BIF', value: 'currency_bif' },
  { label: 'BMD', value: 'currency_bmd' },
  { label: 'BND', value: 'currency_bnd' },
  { label: 'BOB', value: 'currency_bob' },
  { label: 'BRL', value: 'currency_brl' },
  { label: 'BSD', value: 'currency_bsd' },
  { label: 'BWP', value: 'currency_bwp' },
  { label: 'BZD', value: 'currency_bzd' },
  { label: 'CAD', value: 'currency_cad' },
  { label: 'CDF', value: 'currency_cdf' },
  { label: 'CHF', value: 'currency_chf' },
  { label: 'CLP', value: 'currency_clp' },
  { label: 'CNY', value: 'currency_cny' },
  { label: 'COP', value: 'currency_cop' },
  { label: 'CRC', value: 'currency_crc' },
  { label: 'CVE', value: 'currency_cve' },
  { label: 'CZK', value: 'currency_czk' },
  { label: 'DJF', value: 'currency_djf' },
  { label: 'DKK', value: 'currency_dkk' },
  { label: 'DOP', value: 'currency_dop' },
  { label: 'DZD', value: 'currency_dzd' },
  { label: 'EGP', value: 'currency_egp' },
  { label: 'ETB', value: 'currency_etb' },
  { label: 'FJD', value: 'currency_fjd' },
  { label: 'FKP', value: 'currency_fkp' },
  { label: 'GEL', value: 'currency_gel' },
  { label: 'GIP', value: 'currency_gip' },
  { label: 'GMD', value: 'currency_gmd' },
  { label: 'GNF', value: 'currency_gnf' },
  { label: 'GTQ', value: 'currency_gtq' },
  { label: 'GYD', value: 'currency_gyd' },
  { label: 'HKD', value: 'currency_hkd' },
  { label: 'HNL', value: 'currency_hnl' },
  { label: 'HRK', value: 'currency_hrk' },
  { label: 'HTG', value: 'currency_htg' },
  { label: 'HUF', value: 'currency_huf' },
  { label: 'IDR', value: 'currency_idr' },
  { label: 'ILS', value: 'currency_ils' },
  { label: 'INR', value: 'currency_inr' },
  { label: 'ISK', value: 'currency_isk' },
  { label: 'JMD', value: 'currency_jmd' },
  { label: 'JPY', value: 'currency_jpy' },
  { label: 'KES', value: 'currency_kes' },
  { label: 'KGS', value: 'currency_kgs' },
  { label: 'KHR', value: 'currency_khr' },
  { label: 'KMF', value: 'currency_kmf' },
  { label: 'KRW', value: 'currency_krw' },
  { label: 'KWD', value: 'currency_kwd' },
  { label: 'KYD', value: 'currency_kyd' },
  { label: 'KZT', value: 'currency_kzt' },
  { label: 'LAK', value: 'currency_lak' },
  { label: 'LBP', value: 'currency_lbp' },
  { label: 'LKR', value: 'currency_lkr' },
  { label: 'LRD', value: 'currency_lrd' },
  { label: 'LSL', value: 'currency_lsl' },
  { label: 'MAD', value: 'currency_mad' },
  { label: 'MDL', value: 'currency_mdl' },
  { label: 'MGA', value: 'currency_mga' },
  { label: 'MKD', value: 'currency_mkd' },
  { label: 'MNT', value: 'currency_mnt' },
  { label: 'MOP', value: 'currency_mop' },
  { label: 'MRO', value: 'currency_mro' },
  { label: 'MUR', value: 'currency_mur' },
  { label: 'MVR', value: 'currency_mvr' },
  { label: 'MWK', value: 'currency_mwk' },
  { label: 'MXN', value: 'currency_mxn' },
  { label: 'MYR', value: 'currency_myr' },
  { label: 'MZN', value: 'currency_mzn' },
  { label: 'NAD', value: 'currency_nad' },
  { label: 'NGN', value: 'currency_ngn' },
  { label: 'NIO', value: 'currency_nio' },
  { label: 'NOK', value: 'currency_nok' },
  { label: 'NPR', value: 'currency_npr' },
  { label: 'NZD', value: 'currency_nzd' },
  { label: 'OMR', value: 'currency_omr' },
  { label: 'PAB', value: 'currency_pab' },
  { label: 'PEN', value: 'currency_pen' },
  { label: 'PGK', value: 'currency_pgk' },
  { label: 'PHP', value: 'currency_php' },
  { label: 'PKR', value: 'currency_pkr' },
  { label: 'PLN', value: 'currency_pln' },
  { label: 'PYG', value: 'currency_pyg' },
  { label: 'QAR', value: 'currency_qar' },
  { label: 'RON', value: 'currency_ron' },
  { label: 'RSD', value: 'currency_rsd' },
  { label: 'RUB', value: 'currency_rub' },
  { label: 'RWF', value: 'currency_rwf' },
  { label: 'SAR', value: 'currency_sar' },
  { label: 'SBD', value: 'currency_sbd' },
  { label: 'SCR', value: 'currency_scr' },
  { label: 'SEK', value: 'currency_sek' },
  { label: 'SGD', value: 'currency_sgd' },
  { label: 'SHP', value: 'currency_shp' },
  { label: 'SLL', value: 'currency_sll' },
  { label: 'SOS', value: 'currency_sos' },
  { label: 'SRD', value: 'currency_srd' },
  { label: 'STD', value: 'currency_std' },
  { label: 'SVC', value: 'currency_svc' },
  { label: 'SZL', value: 'currency_szl' },
  { label: 'THB', value: 'currency_thb' },
  { label: 'TJS', value: 'currency_tjs' },
  { label: 'TOP', value: 'currency_top' },
  { label: 'TRY', value: 'currency_try' },
  { label: 'TTD', value: 'currency_ttd' },
  { label: 'TWD', value: 'currency_twd' },
  { label: 'TZS', value: 'currency_tzs' },
  { label: 'UAH', value: 'currency_uah' },
  { label: 'UGX', value: 'currency_ugx' },
  { label: 'UYU', value: 'currency_uyu' },
  { label: 'UZS', value: 'currency_uzs' },
  { label: 'VND', value: 'currency_vnd' },
  { label: 'VUV', value: 'currency_vuv' },
  { label: 'WST', value: 'currency_wst' },
  { label: 'XAF', value: 'currency_xaf' },
  { label: 'XCD', value: 'currency_xcd' },
  { label: 'XOF', value: 'currency_xof' },
  { label: 'XPD', value: 'currency_xpd' },
  { label: 'XPF', value: 'currency_xpf' },
  { label: 'YER', value: 'currency_yer' },
  { label: 'ZAR', value: 'currency_zar' },
  { label: 'ZMW', value: 'currency_zmw' },
]

export const extraValues = isGuest => {
  const vals = []

  for (let i = 1; i <= 10; i++) {
    vals.push({
      label: isGuest
        ? `${i} guest${i === 1 ? '' : 's'}`
        : `${i} luggage${i === 1 ? '' : 's'}`,
      value: i,
    })
  }

  return vals
}

export const currenciesWithCountry = [
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    currencyCode: 'EUR',
    population: '84000',
    capital: 'Andorra la Vella',
    continentName: 'Europe',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    currencyCode: 'AED',
    population: '4975593',
    capital: 'Abu Dhabi',
    continentName: 'Asia',
  },
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    currencyCode: 'AFN',
    population: '29121286',
    capital: 'Kabul',
    continentName: 'Asia',
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    currencyCode: 'XCD',
    population: '86754',
    capital: "St. John's",
    continentName: 'North America',
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    currencyCode: 'XCD',
    population: '13254',
    capital: 'The Valley',
    continentName: 'North America',
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    currencyCode: 'ALL',
    population: '2986952',
    capital: 'Tirana',
    continentName: 'Europe',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    currencyCode: 'AMD',
    population: '2968000',
    capital: 'Yerevan',
    continentName: 'Asia',
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    currencyCode: 'AOA',
    population: '13068161',
    capital: 'Luanda',
    continentName: 'Africa',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    currencyCode: '',
    population: '0',
    capital: '',
    continentName: 'Antarctica',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    currencyCode: 'ARS',
    population: '41343201',
    capital: 'Buenos Aires',
    continentName: 'South America',
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
    currencyCode: 'USD',
    population: '57881',
    capital: 'Pago Pago',
    continentName: 'Oceania',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    currencyCode: 'EUR',
    population: '8205000',
    capital: 'Vienna',
    continentName: 'Europe',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    currencyCode: 'AUD',
    population: '21515754',
    capital: 'Canberra',
    continentName: 'Oceania',
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    currencyCode: 'AWG',
    population: '71566',
    capital: 'Oranjestad',
    continentName: 'North America',
  },
  {
    countryCode: 'AX',
    countryName: 'Åland',
    currencyCode: 'EUR',
    population: '26711',
    capital: 'Mariehamn',
    continentName: 'Europe',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    currencyCode: 'AZN',
    population: '8303512',
    capital: 'Baku',
    continentName: 'Asia',
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    currencyCode: 'BAM',
    population: '4590000',
    capital: 'Sarajevo',
    continentName: 'Europe',
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    currencyCode: 'BBD',
    population: '285653',
    capital: 'Bridgetown',
    continentName: 'North America',
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    currencyCode: 'BDT',
    population: '156118464',
    capital: 'Dhaka',
    continentName: 'Asia',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    currencyCode: 'EUR',
    population: '10403000',
    capital: 'Brussels',
    continentName: 'Europe',
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    currencyCode: 'XOF',
    population: '16241811',
    capital: 'Ouagadougou',
    continentName: 'Africa',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    currencyCode: 'BGN',
    population: '7148785',
    capital: 'Sofia',
    continentName: 'Europe',
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    currencyCode: 'BHD',
    population: '738004',
    capital: 'Manama',
    continentName: 'Asia',
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    currencyCode: 'BIF',
    population: '9863117',
    capital: 'Bujumbura',
    continentName: 'Africa',
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    currencyCode: 'XOF',
    population: '9056010',
    capital: 'Porto-Novo',
    continentName: 'Africa',
  },
  {
    countryCode: 'BL',
    countryName: 'Saint Barthélemy',
    currencyCode: 'EUR',
    population: '8450',
    capital: 'Gustavia',
    continentName: 'North America',
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    currencyCode: 'BMD',
    population: '65365',
    capital: 'Hamilton',
    continentName: 'North America',
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei',
    currencyCode: 'BND',
    population: '395027',
    capital: 'Bandar Seri Begawan',
    continentName: 'Asia',
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    currencyCode: 'BOB',
    population: '9947418',
    capital: 'Sucre',
    continentName: 'South America',
  },
  {
    countryCode: 'BQ',
    countryName: 'Bonaire',
    currencyCode: 'USD',
    population: '18012',
    capital: 'Kralendijk',
    continentName: 'North America',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    currencyCode: 'BRL',
    population: '201103330',
    capital: 'Brasília',
    continentName: 'South America',
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    currencyCode: 'BSD',
    population: '301790',
    capital: 'Nassau',
    continentName: 'North America',
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    currencyCode: 'BTN',
    population: '699847',
    capital: 'Thimphu',
    continentName: 'Asia',
  },
  {
    countryCode: 'BV',
    countryName: 'Bouvet Island',
    currencyCode: 'NOK',
    population: '0',
    capital: '',
    continentName: 'Antarctica',
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    currencyCode: 'BWP',
    population: '2029307',
    capital: 'Gaborone',
    continentName: 'Africa',
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    currencyCode: 'BYR',
    population: '9685000',
    capital: 'Minsk',
    continentName: 'Europe',
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    currencyCode: 'BZD',
    population: '314522',
    capital: 'Belmopan',
    continentName: 'North America',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    currencyCode: 'CAD',
    population: '33679000',
    capital: 'Ottawa',
    continentName: 'North America',
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos [Keeling] Islands',
    currencyCode: 'AUD',
    population: '628',
    capital: 'West Island',
    continentName: 'Asia',
  },
  {
    countryCode: 'CD',
    countryName: 'Democratic Republic of the Congo',
    currencyCode: 'CDF',
    population: '70916439',
    capital: 'Kinshasa',
    continentName: 'Africa',
  },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    currencyCode: 'XAF',
    population: '4844927',
    capital: 'Bangui',
    continentName: 'Africa',
  },
  {
    countryCode: 'CG',
    countryName: 'Republic of the Congo',
    currencyCode: 'XAF',
    population: '3039126',
    capital: 'Brazzaville',
    continentName: 'Africa',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    currencyCode: 'CHF',
    population: '7581000',
    capital: 'Bern',
    continentName: 'Europe',
  },
  {
    countryCode: 'CI',
    countryName: 'Ivory Coast',
    currencyCode: 'XOF',
    population: '21058798',
    capital: 'Yamoussoukro',
    continentName: 'Africa',
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    currencyCode: 'NZD',
    population: '21388',
    capital: 'Avarua',
    continentName: 'Oceania',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    currencyCode: 'CLP',
    population: '16746491',
    capital: 'Santiago',
    continentName: 'South America',
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    currencyCode: 'XAF',
    population: '19294149',
    capital: 'Yaoundé',
    continentName: 'Africa',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    currencyCode: 'CNY',
    population: '1330044000',
    capital: 'Beijing',
    continentName: 'Asia',
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    currencyCode: 'COP',
    population: '47790000',
    capital: 'Bogotá',
    continentName: 'South America',
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    currencyCode: 'CRC',
    population: '4516220',
    capital: 'San José',
    continentName: 'North America',
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    currencyCode: 'CUP',
    population: '11423000',
    capital: 'Havana',
    continentName: 'North America',
  },
  {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    currencyCode: 'CVE',
    population: '508659',
    capital: 'Praia',
    continentName: 'Africa',
  },
  {
    countryCode: 'CW',
    countryName: 'Curacao',
    currencyCode: 'ANG',
    population: '141766',
    capital: 'Willemstad',
    continentName: 'North America',
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    currencyCode: 'AUD',
    population: '1500',
    capital: 'Flying Fish Cove',
    continentName: 'Asia',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    currencyCode: 'EUR',
    population: '1102677',
    capital: 'Nicosia',
    continentName: 'Europe',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czechia',
    currencyCode: 'CZK',
    population: '10476000',
    capital: 'Prague',
    continentName: 'Europe',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    currencyCode: 'EUR',
    population: '81802257',
    capital: 'Berlin',
    continentName: 'Europe',
  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    currencyCode: 'DJF',
    population: '740528',
    capital: 'Djibouti',
    continentName: 'Africa',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    currencyCode: 'DKK',
    population: '5484000',
    capital: 'Copenhagen',
    continentName: 'Europe',
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    currencyCode: 'XCD',
    population: '72813',
    capital: 'Roseau',
    continentName: 'North America',
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    currencyCode: 'DOP',
    population: '9823821',
    capital: 'Santo Domingo',
    continentName: 'North America',
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    currencyCode: 'DZD',
    population: '34586184',
    capital: 'Algiers',
    continentName: 'Africa',
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    currencyCode: 'USD',
    population: '14790608',
    capital: 'Quito',
    continentName: 'South America',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    currencyCode: 'EUR',
    population: '1291170',
    capital: 'Tallinn',
    continentName: 'Europe',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    currencyCode: 'EGP',
    population: '80471869',
    capital: 'Cairo',
    continentName: 'Africa',
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    currencyCode: 'MAD',
    population: '273008',
    capital: 'Laâyoune / El Aaiún',
    continentName: 'Africa',
  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    currencyCode: 'ERN',
    population: '5792984',
    capital: 'Asmara',
    continentName: 'Africa',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    currencyCode: 'EUR',
    population: '46505963',
    capital: 'Madrid',
    continentName: 'Europe',
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    currencyCode: 'ETB',
    population: '88013491',
    capital: 'Addis Ababa',
    continentName: 'Africa',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    currencyCode: 'EUR',
    population: '5244000',
    capital: 'Helsinki',
    continentName: 'Europe',
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    currencyCode: 'FJD',
    population: '875983',
    capital: 'Suva',
    continentName: 'Oceania',
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands',
    currencyCode: 'FKP',
    population: '2638',
    capital: 'Stanley',
    continentName: 'South America',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    currencyCode: 'USD',
    population: '107708',
    capital: 'Palikir',
    continentName: 'Oceania',
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    currencyCode: 'DKK',
    population: '48228',
    capital: 'Tórshavn',
    continentName: 'Europe',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    currencyCode: 'EUR',
    population: '64768389',
    capital: 'Paris',
    continentName: 'Europe',
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    currencyCode: 'XAF',
    population: '1545255',
    capital: 'Libreville',
    continentName: 'Africa',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    currencyCode: 'GBP',
    population: '62348447',
    capital: 'London',
    continentName: 'Europe',
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    currencyCode: 'XCD',
    population: '107818',
    capital: "St. George's",
    continentName: 'North America',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    currencyCode: 'GEL',
    population: '4630000',
    capital: 'Tbilisi',
    continentName: 'Asia',
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    currencyCode: 'EUR',
    population: '195506',
    capital: 'Cayenne',
    continentName: 'South America',
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    currencyCode: 'GBP',
    population: '65228',
    capital: 'St Peter Port',
    continentName: 'Europe',
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    currencyCode: 'GHS',
    population: '24339838',
    capital: 'Accra',
    continentName: 'Africa',
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    currencyCode: 'GIP',
    population: '27884',
    capital: 'Gibraltar',
    continentName: 'Europe',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    currencyCode: 'DKK',
    population: '56375',
    capital: 'Nuuk',
    continentName: 'North America',
  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    currencyCode: 'GMD',
    population: '1593256',
    capital: 'Bathurst',
    continentName: 'Africa',
  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    currencyCode: 'GNF',
    population: '10324025',
    capital: 'Conakry',
    continentName: 'Africa',
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    currencyCode: 'EUR',
    population: '443000',
    capital: 'Basse-Terre',
    continentName: 'North America',
  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    currencyCode: 'XAF',
    population: '1014999',
    capital: 'Malabo',
    continentName: 'Africa',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    currencyCode: 'EUR',
    population: '11000000',
    capital: 'Athens',
    continentName: 'Europe',
  },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    currencyCode: 'GBP',
    population: '30',
    capital: 'Grytviken',
    continentName: 'Antarctica',
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    currencyCode: 'GTQ',
    population: '13550440',
    capital: 'Guatemala City',
    continentName: 'North America',
  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
    currencyCode: 'USD',
    population: '159358',
    capital: 'Hagåtña',
    continentName: 'Oceania',
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    currencyCode: 'XOF',
    population: '1565126',
    capital: 'Bissau',
    continentName: 'Africa',
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    currencyCode: 'GYD',
    population: '748486',
    capital: 'Georgetown',
    continentName: 'South America',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    currencyCode: 'HKD',
    population: '6898686',
    capital: 'Hong Kong',
    continentName: 'Asia',
  },
  {
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    currencyCode: 'AUD',
    population: '0',
    capital: '',
    continentName: 'Antarctica',
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    currencyCode: 'HNL',
    population: '7989415',
    capital: 'Tegucigalpa',
    continentName: 'North America',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    currencyCode: 'HRK',
    population: '4284889',
    capital: 'Zagreb',
    continentName: 'Europe',
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    currencyCode: 'HTG',
    population: '9648924',
    capital: 'Port-au-Prince',
    continentName: 'North America',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    currencyCode: 'HUF',
    population: '9982000',
    capital: 'Budapest',
    continentName: 'Europe',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    currencyCode: 'IDR',
    population: '242968342',
    capital: 'Jakarta',
    continentName: 'Asia',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    currencyCode: 'EUR',
    population: '4622917',
    capital: 'Dublin',
    continentName: 'Europe',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    currencyCode: 'ILS',
    population: '7353985',
    capital: '',
    continentName: 'Asia',
  },
  {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    currencyCode: 'GBP',
    population: '75049',
    capital: 'Douglas',
    continentName: 'Europe',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    currencyCode: 'INR',
    population: '1173108018',
    capital: 'New Delhi',
    continentName: 'Asia',
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    currencyCode: 'USD',
    population: '4000',
    capital: '',
    continentName: 'Asia',
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    currencyCode: 'IQD',
    population: '29671605',
    capital: 'Baghdad',
    continentName: 'Asia',
  },
  {
    countryCode: 'IR',
    countryName: 'Iran',
    currencyCode: 'IRR',
    population: '76923300',
    capital: 'Tehran',
    continentName: 'Asia',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    currencyCode: 'ISK',
    population: '308910',
    capital: 'Reykjavik',
    continentName: 'Europe',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    currencyCode: 'EUR',
    population: '60340328',
    capital: 'Rome',
    continentName: 'Europe',
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    currencyCode: 'GBP',
    population: '90812',
    capital: 'Saint Helier',
    continentName: 'Europe',
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    currencyCode: 'JMD',
    population: '2847232',
    capital: 'Kingston',
    continentName: 'North America',
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    currencyCode: 'JOD',
    population: '6407085',
    capital: 'Amman',
    continentName: 'Asia',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    currencyCode: 'JPY',
    population: '127288000',
    capital: 'Tokyo',
    continentName: 'Asia',
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    currencyCode: 'KES',
    population: '40046566',
    capital: 'Nairobi',
    continentName: 'Africa',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    currencyCode: 'KGS',
    population: '5776500',
    capital: 'Bishkek',
    continentName: 'Asia',
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    currencyCode: 'KHR',
    population: '14453680',
    capital: 'Phnom Penh',
    continentName: 'Asia',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    currencyCode: 'AUD',
    population: '92533',
    capital: 'Tarawa',
    continentName: 'Oceania',
  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    currencyCode: 'KMF',
    population: '773407',
    capital: 'Moroni',
    continentName: 'Africa',
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    currencyCode: 'XCD',
    population: '51134',
    capital: 'Basseterre',
    continentName: 'North America',
  },
  {
    countryCode: 'KP',
    countryName: 'North Korea',
    currencyCode: 'KPW',
    population: '22912177',
    capital: 'Pyongyang',
    continentName: 'Asia',
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    currencyCode: 'KRW',
    population: '48422644',
    capital: 'Seoul',
    continentName: 'Asia',
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    currencyCode: 'KWD',
    population: '2789132',
    capital: 'Kuwait City',
    continentName: 'Asia',
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    currencyCode: 'KYD',
    population: '44270',
    capital: 'George Town',
    continentName: 'North America',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    currencyCode: 'KZT',
    population: '15340000',
    capital: 'Astana',
    continentName: 'Asia',
  },
  {
    countryCode: 'LA',
    countryName: 'Laos',
    currencyCode: 'LAK',
    population: '6368162',
    capital: 'Vientiane',
    continentName: 'Asia',
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    currencyCode: 'LBP',
    population: '4125247',
    capital: 'Beirut',
    continentName: 'Asia',
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    currencyCode: 'XCD',
    population: '160922',
    capital: 'Castries',
    continentName: 'North America',
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    currencyCode: 'CHF',
    population: '35000',
    capital: 'Vaduz',
    continentName: 'Europe',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    currencyCode: 'LKR',
    population: '21513990',
    capital: 'Colombo',
    continentName: 'Asia',
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    currencyCode: 'LRD',
    population: '3685076',
    capital: 'Monrovia',
    continentName: 'Africa',
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    currencyCode: 'LSL',
    population: '1919552',
    capital: 'Maseru',
    continentName: 'Africa',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    currencyCode: 'EUR',
    population: '2944459',
    capital: 'Vilnius',
    continentName: 'Europe',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    currencyCode: 'EUR',
    population: '497538',
    capital: 'Luxembourg',
    continentName: 'Europe',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    currencyCode: 'EUR',
    population: '2217969',
    capital: 'Riga',
    continentName: 'Europe',
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    currencyCode: 'LYD',
    population: '6461454',
    capital: 'Tripoli',
    continentName: 'Africa',
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    currencyCode: 'MAD',
    population: '33848242',
    capital: 'Rabat',
    continentName: 'Africa',
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    currencyCode: 'EUR',
    population: '32965',
    capital: 'Monaco',
    continentName: 'Europe',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova',
    currencyCode: 'MDL',
    population: '4324000',
    capital: 'Chişinău',
    continentName: 'Europe',
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    currencyCode: 'EUR',
    population: '666730',
    capital: 'Podgorica',
    continentName: 'Europe',
  },
  {
    countryCode: 'MF',
    countryName: 'Saint Martin',
    currencyCode: 'EUR',
    population: '35925',
    capital: 'Marigot',
    continentName: 'North America',
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    currencyCode: 'MGA',
    population: '21281844',
    capital: 'Antananarivo',
    continentName: 'Africa',
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    currencyCode: 'USD',
    population: '65859',
    capital: 'Majuro',
    continentName: 'Oceania',
  },
  {
    countryCode: 'MK',
    countryName: 'Macedonia',
    currencyCode: 'MKD',
    population: '2062294',
    capital: 'Skopje',
    continentName: 'Europe',
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    currencyCode: 'XOF',
    population: '13796354',
    capital: 'Bamako',
    continentName: 'Africa',
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar [Burma]',
    currencyCode: 'MMK',
    population: '53414374',
    capital: 'Naypyitaw',
    continentName: 'Asia',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    currencyCode: 'MNT',
    population: '3086918',
    capital: 'Ulan Bator',
    continentName: 'Asia',
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    currencyCode: 'MOP',
    population: '449198',
    capital: 'Macao',
    continentName: 'Asia',
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    currencyCode: 'USD',
    population: '53883',
    capital: 'Saipan',
    continentName: 'Oceania',
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    currencyCode: 'EUR',
    population: '432900',
    capital: 'Fort-de-France',
    continentName: 'North America',
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    currencyCode: 'MRO',
    population: '3205060',
    capital: 'Nouakchott',
    continentName: 'Africa',
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    currencyCode: 'XCD',
    population: '9341',
    capital: 'Plymouth',
    continentName: 'North America',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    currencyCode: 'EUR',
    population: '403000',
    capital: 'Valletta',
    continentName: 'Europe',
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    currencyCode: 'MUR',
    population: '1294104',
    capital: 'Port Louis',
    continentName: 'Africa',
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    currencyCode: 'MVR',
    population: '395650',
    capital: 'Malé',
    continentName: 'Asia',
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    currencyCode: 'MWK',
    population: '15447500',
    capital: 'Lilongwe',
    continentName: 'Africa',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    currencyCode: 'MXN',
    population: '112468855',
    capital: 'Mexico City',
    continentName: 'North America',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    currencyCode: 'MYR',
    population: '28274729',
    capital: 'Kuala Lumpur',
    continentName: 'Asia',
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    currencyCode: 'MZN',
    population: '22061451',
    capital: 'Maputo',
    continentName: 'Africa',
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    currencyCode: 'NAD',
    population: '2128471',
    capital: 'Windhoek',
    continentName: 'Africa',
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    currencyCode: 'XPF',
    population: '216494',
    capital: 'Noumea',
    continentName: 'Oceania',
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    currencyCode: 'XOF',
    population: '15878271',
    capital: 'Niamey',
    continentName: 'Africa',
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    currencyCode: 'AUD',
    population: '1828',
    capital: 'Kingston',
    continentName: 'Oceania',
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    currencyCode: 'NGN',
    population: '154000000',
    capital: 'Abuja',
    continentName: 'Africa',
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    currencyCode: 'NIO',
    population: '5995928',
    capital: 'Managua',
    continentName: 'North America',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    currencyCode: 'EUR',
    population: '16645000',
    capital: 'Amsterdam',
    continentName: 'Europe',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    currencyCode: 'NOK',
    population: '5009150',
    capital: 'Oslo',
    continentName: 'Europe',
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    currencyCode: 'NPR',
    population: '28951852',
    capital: 'Kathmandu',
    continentName: 'Asia',
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    currencyCode: 'AUD',
    population: '10065',
    capital: 'Yaren',
    continentName: 'Oceania',
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    currencyCode: 'NZD',
    population: '2166',
    capital: 'Alofi',
    continentName: 'Oceania',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    currencyCode: 'NZD',
    population: '4252277',
    capital: 'Wellington',
    continentName: 'Oceania',
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    currencyCode: 'OMR',
    population: '2967717',
    capital: 'Muscat',
    continentName: 'Asia',
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    currencyCode: 'PAB',
    population: '3410676',
    capital: 'Panama City',
    continentName: 'North America',
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    currencyCode: 'PEN',
    population: '29907003',
    capital: 'Lima',
    continentName: 'South America',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    currencyCode: 'XPF',
    population: '270485',
    capital: 'Papeete',
    continentName: 'Oceania',
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    currencyCode: 'PGK',
    population: '6064515',
    capital: 'Port Moresby',
    continentName: 'Oceania',
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    currencyCode: 'PHP',
    population: '99900177',
    capital: 'Manila',
    continentName: 'Asia',
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    currencyCode: 'PKR',
    population: '184404791',
    capital: 'Islamabad',
    continentName: 'Asia',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    currencyCode: 'PLN',
    population: '38500000',
    capital: 'Warsaw',
    continentName: 'Europe',
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    currencyCode: 'EUR',
    population: '7012',
    capital: 'Saint-Pierre',
    continentName: 'North America',
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn Islands',
    currencyCode: 'NZD',
    population: '46',
    capital: 'Adamstown',
    continentName: 'Oceania',
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    currencyCode: 'USD',
    population: '3916632',
    capital: 'San Juan',
    continentName: 'North America',
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine',
    currencyCode: 'ILS',
    population: '3800000',
    capital: '',
    continentName: 'Asia',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    currencyCode: 'EUR',
    population: '10676000',
    capital: 'Lisbon',
    continentName: 'Europe',
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    currencyCode: 'USD',
    population: '19907',
    capital: 'Melekeok',
    continentName: 'Oceania',
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    currencyCode: 'PYG',
    population: '6375830',
    capital: 'Asunción',
    continentName: 'South America',
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    currencyCode: 'QAR',
    population: '840926',
    capital: 'Doha',
    continentName: 'Asia',
  },
  {
    countryCode: 'RE',
    countryName: 'Réunion',
    currencyCode: 'EUR',
    population: '776948',
    capital: 'Saint-Denis',
    continentName: 'Africa',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    currencyCode: 'RON',
    population: '21959278',
    capital: 'Bucharest',
    continentName: 'Europe',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    currencyCode: 'RSD',
    population: '7344847',
    capital: 'Belgrade',
    continentName: 'Europe',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    currencyCode: 'RUB',
    population: '140702000',
    capital: 'Moscow',
    continentName: 'Europe',
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    currencyCode: 'RWF',
    population: '11055976',
    capital: 'Kigali',
    continentName: 'Africa',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    currencyCode: 'SAR',
    population: '25731776',
    capital: 'Riyadh',
    continentName: 'Asia',
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    currencyCode: 'SBD',
    population: '559198',
    capital: 'Honiara',
    continentName: 'Oceania',
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    currencyCode: 'SCR',
    population: '88340',
    capital: 'Victoria',
    continentName: 'Africa',
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    currencyCode: 'SDG',
    population: '35000000',
    capital: 'Khartoum',
    continentName: 'Africa',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    currencyCode: 'SEK',
    population: '9828655',
    capital: 'Stockholm',
    continentName: 'Europe',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    currencyCode: 'SGD',
    population: '4701069',
    capital: 'Singapore',
    continentName: 'Asia',
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena',
    currencyCode: 'SHP',
    population: '7460',
    capital: 'Jamestown',
    continentName: 'Africa',
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    currencyCode: 'EUR',
    population: '2007000',
    capital: 'Ljubljana',
    continentName: 'Europe',
  },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    currencyCode: 'NOK',
    population: '2550',
    capital: 'Longyearbyen',
    continentName: 'Europe',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    currencyCode: 'EUR',
    population: '5455000',
    capital: 'Bratislava',
    continentName: 'Europe',
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    currencyCode: 'SLL',
    population: '5245695',
    capital: 'Freetown',
    continentName: 'Africa',
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    currencyCode: 'EUR',
    population: '31477',
    capital: 'San Marino',
    continentName: 'Europe',
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    currencyCode: 'XOF',
    population: '12323252',
    capital: 'Dakar',
    continentName: 'Africa',
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    currencyCode: 'SOS',
    population: '10112453',
    capital: 'Mogadishu',
    continentName: 'Africa',
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    currencyCode: 'SRD',
    population: '492829',
    capital: 'Paramaribo',
    continentName: 'South America',
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan',
    currencyCode: 'SSP',
    population: '8260490',
    capital: 'Juba',
    continentName: 'Africa',
  },
  {
    countryCode: 'ST',
    countryName: 'São Tomé and Príncipe',
    currencyCode: 'STD',
    population: '175808',
    capital: 'São Tomé',
    continentName: 'Africa',
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    currencyCode: 'USD',
    population: '6052064',
    capital: 'San Salvador',
    continentName: 'North America',
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten',
    currencyCode: 'ANG',
    population: '37429',
    capital: 'Philipsburg',
    continentName: 'North America',
  },
  {
    countryCode: 'SY',
    countryName: 'Syria',
    currencyCode: 'SYP',
    population: '22198110',
    capital: 'Damascus',
    continentName: 'Asia',
  },
  {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    currencyCode: 'SZL',
    population: '1354051',
    capital: 'Mbabane',
    continentName: 'Africa',
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    currencyCode: 'USD',
    population: '20556',
    capital: 'Cockburn Town',
    continentName: 'North America',
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    currencyCode: 'XAF',
    population: '10543464',
    capital: "N'Djamena",
    continentName: 'Africa',
  },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    currencyCode: 'EUR',
    population: '140',
    capital: 'Port-aux-Français',
    continentName: 'Antarctica',
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    currencyCode: 'XOF',
    population: '6587239',
    capital: 'Lomé',
    continentName: 'Africa',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    currencyCode: 'THB',
    population: '67089500',
    capital: 'Bangkok',
    continentName: 'Asia',
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    currencyCode: 'TJS',
    population: '7487489',
    capital: 'Dushanbe',
    continentName: 'Asia',
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    currencyCode: 'NZD',
    population: '1466',
    capital: '',
    continentName: 'Oceania',
  },
  {
    countryCode: 'TL',
    countryName: 'East Timor',
    currencyCode: 'USD',
    population: '1154625',
    capital: 'Dili',
    continentName: 'Oceania',
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    currencyCode: 'TMT',
    population: '4940916',
    capital: 'Ashgabat',
    continentName: 'Asia',
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    currencyCode: 'TND',
    population: '10589025',
    capital: 'Tunis',
    continentName: 'Africa',
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    currencyCode: 'TOP',
    population: '122580',
    capital: "Nuku'alofa",
    continentName: 'Oceania',
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    currencyCode: 'TRY',
    population: '77804122',
    capital: 'Ankara',
    continentName: 'Asia',
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    currencyCode: 'TTD',
    population: '1228691',
    capital: 'Port of Spain',
    continentName: 'North America',
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    currencyCode: 'AUD',
    population: '10472',
    capital: 'Funafuti',
    continentName: 'Oceania',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    currencyCode: 'TWD',
    population: '22894384',
    capital: 'Taipei',
    continentName: 'Asia',
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    currencyCode: 'TZS',
    population: '41892895',
    capital: 'Dodoma',
    continentName: 'Africa',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    currencyCode: 'UAH',
    population: '45415596',
    capital: 'Kiev',
    continentName: 'Europe',
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    currencyCode: 'UGX',
    population: '33398682',
    capital: 'Kampala',
    continentName: 'Africa',
  },
  {
    countryCode: 'UM',
    countryName: 'U.S. Minor Outlying Islands',
    currencyCode: 'USD',
    population: '0',
    capital: '',
    continentName: 'Oceania',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    currencyCode: 'USD',
    population: '310232863',
    capital: 'Washington',
    continentName: 'North America',
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    currencyCode: 'UYU',
    population: '3477000',
    capital: 'Montevideo',
    continentName: 'South America',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    currencyCode: 'UZS',
    population: '27865738',
    capital: 'Tashkent',
    continentName: 'Asia',
  },
  {
    countryCode: 'VA',
    countryName: 'Vatican City',
    currencyCode: 'EUR',
    population: '921',
    capital: 'Vatican City',
    continentName: 'Europe',
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    currencyCode: 'XCD',
    population: '104217',
    capital: 'Kingstown',
    continentName: 'North America',
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    currencyCode: 'VEF',
    population: '27223228',
    capital: 'Caracas',
    continentName: 'South America',
  },
  {
    countryCode: 'VG',
    countryName: 'British Virgin Islands',
    currencyCode: 'USD',
    population: '21730',
    capital: 'Road Town',
    continentName: 'North America',
  },
  {
    countryCode: 'VI',
    countryName: 'U.S. Virgin Islands',
    currencyCode: 'USD',
    population: '108708',
    capital: 'Charlotte Amalie',
    continentName: 'North America',
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    currencyCode: 'VND',
    population: '89571130',
    capital: 'Hanoi',
    continentName: 'Asia',
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    currencyCode: 'VUV',
    population: '221552',
    capital: 'Port Vila',
    continentName: 'Oceania',
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    currencyCode: 'XPF',
    population: '16025',
    capital: 'Mata-Utu',
    continentName: 'Oceania',
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    currencyCode: 'WST',
    population: '192001',
    capital: 'Apia',
    continentName: 'Oceania',
  },
  {
    countryCode: 'XK',
    countryName: 'Kosovo',
    currencyCode: 'EUR',
    population: '1800000',
    capital: 'Pristina',
    continentName: 'Europe',
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    currencyCode: 'YER',
    population: '23495361',
    capital: 'Sanaa',
    continentName: 'Asia',
  },
  {
    countryCode: 'YT',
    countryName: 'Mayotte',
    currencyCode: 'EUR',
    population: '159042',
    capital: 'Mamoudzou',
    continentName: 'Africa',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    currencyCode: 'ZAR',
    population: '49000000',
    capital: 'Pretoria',
    continentName: 'Africa',
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    currencyCode: 'ZMW',
    population: '13460305',
    capital: 'Lusaka',
    continentName: 'Africa',
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    currencyCode: 'ZWL',
    population: '13061000',
    capital: 'Harare',
    continentName: 'Africa',
  },
]

export const defaultPaymentTerms = [
  { label: 'Immediate payment', value: 'payment_term_immediate' },
  { label: 'Weekly', value: 'payment_term_weekly' },
  { label: 'Monthly', value: 'payment_term_monthly' },
]
export const defaultBookingAccess = [
  { label: 'All access', value: 'all_access' },
  { label: 'Restricted access', value: 'restricted_access' },
]
export const defaultTeamPrivacy = [
  { label: 'All users (can view all users)', value: 'all_users' },
  { label: 'Admin only (can view all users)', value: 'admin_only' },
]

export const defaultRateTypes = [
  { label: 'Net Rates', value: 'rate_type_net' },
  { label: 'Commissionable Rates', value: 'rate_type_full' },
]

export const companyRoles = [
  { label: 'User', value: ['company_user'] },
  { label: 'Admin', value: ['company_admin', 'company_user'] },
]

export const accessRoles = [
  { label: 'Agent', value: ['system_agent'] },
  { label: 'Admin', value: ['system_admin'] },
  { label: 'Manager', value: ['system_manager'] },
]

export const defaultPaymentMethods = [
  'Tom Ripert, **** 7059, 2/2022',
  'Tom Example, **** 8450, 5/2024',
]
export const defaultVehicleCategories = [
  { label: 'Convertible', value: 'convertibles' }, //SD
  { label: 'Sports', value: 'sports' }, //SD
  { label: 'Executive', value: 'executives' }, //SD
  { label: 'City', value: 'city' }, //SD
  { label: 'Supercar', value: 'supercar' }, //SD
  { label: 'Classic', value: 'classic' }, //SD
  { label: 'SUV', value: 'suvs' }, //SD&C
  { label: 'Van', value: 'van' }, //SD&C
  // { label: 'Business', value: 'standard' }, // C => label updated from Standard to Business
  { label: 'Business', value: 'business' }, // C
  { label: 'Minivan', value: 'plus' }, // C => label updated from Plus to Minivan
  { label: 'Minivan', value: 'minivan' }, // C
  { label: 'First', value: 'executive' }, // C => label updated from Executive to First
  { label: 'First', value: 'first' }, // C
  { label: 'Minibus', value: 'minibus' }, // => C
  { label: 'Coach', value: 'coach' }, // => C
  { label: 'Armoured', value: 'armoured' }, // => C
  { label: 'Limousine', value: 'limousine' }, // => C
  { label: 'Luxe', value: 'luxe' }, // => C
  { label: 'Standard', value: 'standard' }, // => C
]
export const defaultSelfDriveСategory = [
  { label: 'City', value: 'city' }, // new
  { label: 'Executive', value: 'executives' },
  { label: 'SUV', value: 'suvs' },
  { label: 'Convertible', value: 'convertibles' },
  { label: 'Sports', value: 'sports' },
  { label: 'Supercar', value: 'supercar' }, // new
  { label: 'Van', value: 'van' }, // new
  { label: 'Classic', value: 'classic' }, // new
  // { label: 'Executive', value: 'executive' }, // removed as extra
]

export const defaultChauffeuredСategory = [
  { label: 'Business', value: 'business' }, // => new
  { label: 'Minivan', value: 'minivan' }, // => new
  { label: 'SUV', value: 'suvs' }, // => new
  { label: 'First', value: 'first' }, // => new
  { label: 'Luxe', value: 'luxe' }, // removed in new list
  { label: 'Van', value: 'van' }, // => new
  { label: 'Minibus', value: 'minibus' }, // => new
  { label: 'Coach', value: 'coach' }, // => new
  { label: 'Armoured', value: 'armoured' }, // => new
  { label: 'Limousine', value: 'limousine' }, // => new
  { label: 'Standard', value: 'standard' },
  // { label: 'Executive', value: 'executives' }, // removed as extra
]

export const defaultYears = [
  { label: '2015', value: '2015' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
]

export const getDefaultYears = (startYear, endYear) => {
  const years = []

  const _start = startYear || 2015
  const _end = endYear || moment().year()

  for (let i = _start; i <= _end; i++) {
    years.push({ label: i, value: i })
  }

  return years
}
export const paginationPerPageOptions = [10, 20, 50, 100]
export const defaultLimit = 40

export const defaultModes = [
  { label: 'Chauffeured', value: 'chauffeured' },
  { label: 'Car rental', value: 'self_drive' },
]

export const defaultRates = [
  { label: 'Transfer', value: 'transfer' },
  { label: 'By the hour', value: 'chauffeurService' },
  { label: 'Car rental', value: 'carRental' },
  // { label: 'From A-B', value: 'transfer' },
  // { label: 'Hourly', value: 'chauffeurService' },
  // { label: 'Car rental', value: 'carRental' },
]
export const defaultDraftRates = [
  { label: 'Transfer', value: 'transfer' },
  { label: 'Transfer', value: 'returnTransfer' },
  { label: 'By the hour', value: 'chauffeurService' },
  { label: 'Car rental', value: 'carRental' },
]

export const mapModes = {
  chauffeured: 'Chauffeured',
  self_drive: 'Self-drive',
}

export const mapRates = {
  daily: 'Daily',
  hourly: 'Hourly',
  aToB: 'A-B',
}

export const defaultDistanceUnits = [{ label: 'Miles', value: 'miles' }]

export const defaultFleetOptions = [
  { value: '1-5 vehicles', label: '1-5 vehicles' },
  { value: '5-15 vehicles', label: '5-15 vehicles' },
  { value: '15-25 vehicles', label: '15-25 vehicles' },
  { value: '25-50 vehicles', label: '25-50 vehicles' },
  { value: '50+ vehicles', label: '50+ vehicles' },
]
export const defaultBookingStatus = [
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Completed', value: 'completed' },
  // { label: 'Quote Request', value: 'quote_in_progress' },
  { label: 'Quote received', value: 'quote_in_progress' },
  // { label: 'Request', value: 'is_request' },
  { label: 'Quote requested', value: 'is_request' },
  { label: 'Pending', value: 'in_review' },
  // { label: 'Payment declined', value: 'transaction_declined' },
  { label: 'Unavailable', value: 'unavailable' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  { label: 'Cancelled', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled', value: 'cancelled_by_agent' },
  { label: 'Quote', value: 'quote' },
  { label: 'Expired', value: 'quote_expired' },
  { label: 'Change request', value: 'change_request' },
  { label: 'Changes sent', value: 'changes_sent' },
]
export const defaultBookingStatusAdmin = [
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Completed', value: 'completed' },
  { label: 'Quote sent', value: 'quote_in_progress' },
  { label: 'Quote requested', value: 'is_request' },
  { label: 'Pending', value: 'in_review' },
  // { label: 'Payment declined', value: 'transaction_declined' },
  { label: 'Unavailable', value: 'unavailable' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  { label: 'Cancelled', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled', value: 'cancelled_by_agent' },
  { label: 'Quote', value: 'quote' },
  { label: 'Expired', value: 'quote_expired' },
  { label: 'Change request', value: 'change_request' },
  { label: 'Changes sent', value: 'changes_sent' },
]
export const defaultBookingStatusClient = [
  { label: 'Pending', value: 'in_review' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Completed', value: 'completed' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  //{ label: 'Cancellation accepted', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled', value: 'cancelled_by_agent' },
  { label: 'Unavailable', value: 'unavailable' },
  { label: 'Change request', value: 'change_request' },
  { label: 'Changes sent', value: 'changes_sent' },
]
export const defaultBookingStatusList = [
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Completed', value: 'completed' },
  { label: 'Quote sent', value: 'quote_in_progress' },
  { label: 'Quote requested', value: 'is_request' },
  { label: 'Pending', value: 'in_review' },
  // { label: 'Payment declined', value: 'transaction_declined' },
  { label: 'Unavailable', value: 'unavailable' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  { label: 'Cancelled (User)', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled (Admin)', value: 'cancelled_by_agent' },
  { label: 'Quote', value: 'quote' },
  { label: 'Expired', value: 'quote_expired' },
  { label: 'Change request', value: 'change_request' },
  { label: 'Changes sent', value: 'changes_sent' },
]
export const defaultPaymentStatusAdmin = [
  { label: 'Paid', value: 'paid' },
  { label: 'Payment due', value: 'payment_due' },
  {
    label: 'Non-billable',
    value: 'non_billable',
  },
  { label: 'Declined', value: 'transaction_declined' },
  { label: 'Disputed', value: 'disputed' },
  { label: 'Refunded', value: 'refunded' },
]
export const defaultBookingStatusPartner = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'in_review' },
  { label: 'Outsourced', value: 'outsourced' },
]

export const defaultQuoteStatus = [
  { label: 'Quote sent', value: 'quote_in_progress' },
  { label: 'Unavailable', value: 'unavailable' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  { label: 'Cancellation accepted', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled by agent', value: 'cancelled_by_agent' },
  { label: 'Expired', value: 'quote_expired' },
]
export const defaultQuoteStatusClient = [
  { label: 'Quote requested', value: 'is_request' },
  { label: 'Quote received', value: 'quote_in_progress' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  //{ label: 'Cancellation accepted', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled', value: 'cancelled_by_agent' },
  { label: 'Unavailable', value: 'unavailable' },
  { label: 'Expired', value: 'quote_expired' },
]

export const defaultDispatchStatus = [
  { label: 'Show all', value: '' },
  { label: 'No', value: 'not_dispatched' },
  { label: 'Dispatched', value: 'dispatched' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Completed', value: 'completed' },
  { label: 'Declined', value: 'declined' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Quote requested', value: 'quote_requested' },
]
export const defaultDispatchStatusPartner = [
  { label: 'Show all', value: '' },
  { label: 'Dispatched', value: 'dispatched' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Declined', value: 'declined' },
]

export const defaultServiceStatus = [
  { label: 'Show all', value: '' },
  { label: 'On the way', value: 'on_the_way' },
  { label: 'On location', value: 'on_location' },
  { label: 'On board', value: 'passenger_onboard' },
  { label: '10 mins away', value: 'ten_mins_away' },
  { label: 'Dropped off', value: 'passenger_dropped_off' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Not on the way', value: 'not_on_the_way' },
  { label: 'Not on location', value: 'not_on_location' },
  { label: 'Not on board', value: 'not_onboard' },
  { label: 'Not dropped off', value: 'not_dropped_off' },
  { label: 'Not delivered', value: 'not_delivered' },
]

export const defaultTripStatus = [
  { label: 'Received', value: 'received' },
  { label: 'Changed', value: 'changed' },
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Assigned', value: 'assigned' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'On the way', value: 'on_the_way' },
  { label: '10 mins away', value: 'ten_mins_away' },
  { label: 'On location', value: 'on_location' },
  { label: 'On board', value: 'passenger_onboard' },
  { label: 'Dropped off', value: 'passenger_dropped_off' },
  { label: 'Not assigned', value: 'not_assigned' },
  { label: 'Not on the way', value: 'not_on_the_way' },
  { label: 'Not on location', value: 'not_on_location' },
  { label: 'Not onboard', value: 'not_onboard' },
  { label: 'Not dropped off', value: 'not_dropped_off' },
  { label: 'Not accepted', value: 'not_accepted' },
]

export const defaultChangesStatus = [
  { label: 'Show all', value: '' },
  { label: 'New', value: 'new' },
  { label: 'Sent', value: 'sent' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Declined', value: 'declined' },
]

export const defaultRegions = [
  { label: 'Show all', value: '' },
  { label: 'Africa', value: 'Africa' },
  { label: 'Asia', value: 'Asia' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Caribbeans', value: 'Caribbeans' },
  { label: 'Europe', value: 'Europe' },
  { label: 'LATAM', value: 'LATAM' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Middle East', value: 'Middle East' },
  { label: 'Oceania', value: 'Oceania' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'United States', value: 'United States' },
  { label: 'Brazil', value: 'Brazil' },
]

export const defaultStatemntOfAccountStatus = [
  { label: 'Unpaid', value: 'outstanding' },
  { label: 'Paid', value: 'paid' },
]

export const defaultPaymentStatus = [
  { label: 'Show all', value: '' },
  { label: 'Unpaid', value: 'unPaid' },
  { label: 'Paid', value: 'paid' },
]
export const defaultChauffeurStatus = [
  { label: 'Show all', value: '' },
  { label: 'No', value: 'no' },
  { label: 'Received', value: 'received' },
  { label: 'Yes', value: 'yes' },
]
export const defaultSignUpStatus = [
  { label: 'All', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]
export const defaultChauffeurStatusPartner = [
  { label: 'Show all', value: '' },
  { label: 'No', value: 'no' },
  { label: 'Yes', value: 'yes' },
]
export const defaultAlertsStatus = [
  { label: 'Show all', value: '' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]
export const defaultVipPassengerStatus = [
  { label: 'Show all', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]
export const defaultBillingStatus = [
  { label: 'Show all', value: '' },
  { label: 'Deferred', value: 'deferred' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Standard', value: 'standard' },
]
export const defaultNotesStatus = [
  { label: 'Show all', value: '' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]
export const defaultModeType = [
  { label: 'Transfer', value: 'airport_transfer' },
  { label: 'By the hour', value: 'chauffeur_services' },
  { label: 'Car rental', value: 'car_rentals' },
  { label: 'Transfer', value: 'return_transfer' },
  { label: 'Transfer', value: 'departure_transfer' },
  { label: 'Meet & greet', value: 'isMeetGreet' },
]
export const defaultNetworkMode = [
  { label: 'Companies', value: 'clients' },
  { label: 'Partners', value: 'partners' },
  { label: 'Individuals', value: 'individuals' },
]
export const defaultPartnerMode = [
  { label: 'Show all', value: '' },
  { label: 'Chauffeur', value: 'chauffeur' },
  { label: 'Car rental', value: 'carRental' },
  { label: 'VIP Meet & Greet', value: 'vipMeetAndGreet' },
]

export const defaultPartnerStatus = [
  { label: 'Show all', value: '' },
  { label: 'PREFERRED', value: 'preferred' },
  { label: 'USED', value: 'used' },
  { label: 'NEW', value: 'new' },
  { label: 'UNVERIFIED', value: 'unverified' },
  { label: 'BLACKLISTED', value: 'blacklisted' },
  { label: 'BACKUP', value: 'backup' },
]

export const defaultPartnerStatusNew = [
  { label: 'Show all', value: '' },
  { label: 'GOLD', value: 'gold' },
  { label: 'SILVER', value: 'silver' },
  { label: 'BRONZE', value: 'bronze' },
  { label: 'BACKUP', value: 'backup' },
  { label: 'NEW', value: 'new' },
  { label: 'BLACKLISTED', value: 'blacklisted' },
  { label: 'INAPT', value: 'inapt' },
  { label: 'EXCLUSIVE', value: 'exclusive' },
]

export const defaultUserStatus = [
  { label: 'INAPT', value: 'inapt' },
  { label: 'NEW', value: 'new' },
  { label: 'INACTIVE', value: 'inactive' },
  { label: 'INVITED', value: 'invited' },
  { label: 'DORMANT', value: 'dormant' },
  { label: 'EARLY BIRD', value: 'early_bird' },
  { label: 'ACTIVE', value: 'active' },
  { label: 'LAPSED', value: 'lapsed' },
  { label: 'CHURNED', value: 'churned' },
  { label: 'FRAUD', value: 'fraud' },
]

export const defaultCompanyStatus = [
  { label: 'INAPT', value: 'inapt' },
  { label: 'NEW', value: 'new' },
  { label: 'INACTIVE', value: 'inactive' },
  { label: 'INVITED', value: 'invited' },
  { label: 'DORMANT', value: 'dormant' },
  { label: 'EARLY BIRD', value: 'early_bird' },
  { label: 'ACTIVE', value: 'active' },
  { label: 'LAPSED', value: 'lapsed' },
  { label: 'CHURNED', value: 'churned' },
  { label: 'FRAUD', value: 'fraud' },
]

export const defaultExteriorColour = [
  { label: 'Black', value: 'Black' },
  { label: 'Grey', value: 'Grey' },
  { label: 'Silver', value: 'Silver' },
  { label: 'White', value: 'White' },
  { label: 'Blue', value: 'Blue' },
  { label: 'Beige', value: 'Beige' },
  { label: 'Brown', value: 'Brown' },
  { label: 'Green', value: 'Green' },
  { label: 'Red', value: 'Red' },
  { label: 'Yellow', value: 'Yellow' },
]

export const defaultInteriorColour = [
  { label: 'Black', value: 'Black' },
  { label: 'Grey', value: 'Grey' },
  { label: 'Beige', value: 'Beige' },
  { label: 'Cream', value: 'Cream' },
  { label: 'White', value: 'White' },
  { label: 'Brown', value: 'Brown' },
  { label: 'Navy', value: 'Navy' },
  { label: 'Red', value: 'Red' },
]

export const defaultModelYear = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
  { label: '2021', value: '2021' },
  { label: '2020', value: '2020' },
  { label: '2019', value: '2019' },
  { label: 'Other', value: 'Other' },
]

// export const defaultBookingService = [
//   { label: 'Transfer', value: 'airport_transfer' },
//   { label: 'Chauffeur Services', value: 'chauffeur_services' },
//   { label: 'Car rental', value: 'car_rentals' },
// ]

export const defaultBookingService = [
  { label: 'Transfer', value: 'airport_transfer' },
  { label: 'By the hour', value: 'chauffeur_services' },
  { label: 'Car rental', value: 'car_rentals' },
  { label: 'Transfer', value: 'return_transfer' },
  { label: 'Departure transfer', value: 'departure_transfer' },
]

export const defaultQuoteService = [
  { label: 'Transfer', value: 'airport_transfer' },
  { label: 'By the hour', value: 'chauffeur_services' },
  { label: 'Car rental', value: 'car_rentals' },
  { label: 'Meet & greet', value: 'isMeetGreet' },
]

export const defaultBookingServicePartner = [
  { label: 'Transfer', value: 'airport_transfer' },
  { label: 'By the hour', value: 'chauffeur_services' },
  { label: 'Car rental', value: 'car_rentals' },
  { label: 'Transfer', value: 'return_transfer' },
  { label: 'Transfer', value: 'departure_transfer' },
]

export const defaultVipService = [
  { label: 'Meet & greet', value: 'meetAndGreet' },
]

export const defaultPaymentCardTypes = [
  {
    type: 'visa',
    altType: 'visa',
    label: 'Visa',
    icon: 'visa.svg',
  },
  {
    type: 'Visa',
    altType: 'visa',
    label: 'Visa',
    icon: 'visa.svg',
  },
  {
    type: 'mastercard',
    altType: 'mastercard',
    label: 'Mastercard',
    icon: 'mastercard.svg',
  },
  {
    type: 'MasterCard',
    altType: 'mastercard',
    label: 'Mastercard',
    icon: 'mastercard.svg',
  },
  {
    type: 'amex',
    altType: 'american-express',
    label: 'American Express',
    icon: 'amex.svg',
  },
  {
    type: 'American Express',
    altType: 'american-express',
    label: 'American Express',
    icon: 'amex.svg',
  },
  {
    type: 'dinersclub',
    altType: 'diners-club',
    label: 'Diners Club',
    icon: 'dinersclub.svg',
  },
  {
    type: 'discover',
    altType: 'discover',
    label: 'Discover',
    icon: 'discover.svg',
  },
  {
    type: 'jcb',
    altType: 'jcb',
    label: 'JCB',
    icon: 'jcb.svg',
  },
  {
    type: 'unionpay',
    altType: 'unionpay',
    label: 'UnionPay',
    icon: 'unionpay.svg',
  },
  {
    type: 'maestro',
    altType: 'maestro',
    label: 'Maestro',
    icon: 'placeholder.svg',
  },
  {
    value: 'elo',
    altType: 'elo',
    label: 'Elo',
    icon: 'placeholder.svg',
  },
  {
    type: 'hipercard',
    altType: 'hipercard',
    label: 'Hipercard',
    icon: 'hipercard.svg',
  },
]
export const defaultPassengeAge = [
  { label: 21, value: 21 },
  { label: 22, value: 22 },
  { label: 23, value: 23 },
  { label: 24, value: 24 },
  { label: 25, value: 25 },
  { label: 26, value: 26 },
  { label: 27, value: 27 },
  { label: 28, value: 28 },
  { label: 29, value: 29 },
  { label: 30, value: 30 },
  { label: 31, value: 31 },
  { label: 32, value: 32 },
  { label: 33, value: 33 },
  { label: 34, value: 34 },
  { label: 35, value: 35 },
  { label: 36, value: 36 },
  { label: 37, value: 37 },
  { label: 38, value: 38 },
  { label: 39, value: 39 },
  { label: 40, value: 40 },
  { label: 41, value: 41 },
  { label: 42, value: 42 },
  { label: 43, value: 43 },
  { label: 44, value: 44 },
  { label: 45, value: 45 },
  { label: 46, value: 46 },
  { label: 47, value: 47 },
  { label: 48, value: 48 },
  { label: 49, value: 49 },
  { label: 50, value: 50 },
  { label: 51, value: 51 },
  { label: 52, value: 52 },
  { label: 53, value: 53 },
  { label: 54, value: 54 },
  { label: 55, value: 55 },
  { label: 56, value: 56 },
  { label: 57, value: 57 },
  { label: 58, value: 58 },
  { label: 59, value: 59 },
  { label: 60, value: 60 },
  { label: 61, value: 61 },
  { label: 62, value: 62 },
  { label: 63, value: 63 },
  { label: 64, value: 64 },
  { label: 65, value: 65 },
  { label: 66, value: 66 },
  { label: 67, value: 67 },
  { label: 68, value: 68 },
  { label: 69, value: 69 },
  { label: 70, value: 70 },
  { label: 71, value: 71 },
  { label: 72, value: 72 },
  { label: 73, value: 73 },
  { label: 74, value: 74 },
  { label: 75, value: 75 },
  { label: 76, value: 76 },
  { label: 77, value: 77 },
  { label: 78, value: 78 },
  { label: 79, value: 79 },
  { label: 80, value: 80 },
  { label: 81, value: 81 },
  { label: 82, value: 82 },
  { label: 83, value: 83 },
  { label: 84, value: 84 },
  { label: 85, value: 85 },
  { label: 86, value: 86 },
  { label: 87, value: 87 },
  { label: 88, value: 88 },
  { label: 89, value: 89 },
  { label: 90, value: 90 },
  { label: 91, value: 91 },
  { label: 92, value: 92 },
  { label: 93, value: 93 },
  { label: 94, value: 94 },
  { label: 95, value: 95 },
  { label: 96, value: 96 },
  { label: 97, value: 97 },
  { label: 98, value: 98 },
  { label: 99, value: 99 },
]

export const defaultPassengerTitles = [
  {
    label: 'Mr',
    value: 'Mr',
  },
  {
    label: 'Mrs',
    value: 'Mrs',
  },
  {
    label: 'Miss',
    value: 'Miss',
  },
  {
    label: 'Ms',
    value: 'Ms',
  },
  {
    label: 'HRH',
    value: 'HRH',
  },
  {
    label: 'Mstr',
    value: 'Mstr',
  },
  {
    label: 'Capt',
    value: 'Capt',
  },
  {
    label: 'Prof',
    value: 'Prof',
  },
  {
    label: 'Dr',
    value: 'Dr',
  },
  {
    label: 'Dame',
    value: 'Dame',
  },
  {
    label: 'Lady',
    value: 'Lady',
  },
  {
    label: 'Lord',
    value: 'Lord',
  },
  {
    label: 'The Rt Hon',
    value: 'The Rt Hon',
  },
  {
    label: 'Rabbi',
    value: 'Rabbi',
  },
  {
    label: 'Rev',
    value: 'Rev',
  },
  {
    label: 'Sir',
    value: 'Sir',
  },
  {
    label: 'Baroness',
    value: 'Baroness',
  },
  {
    label: 'Baron',
    value: 'Baron',
  },
  {
    label: 'Viscount',
    value: 'Viscount',
  },
  {
    label: 'Viscountess',
    value: 'Viscountess',
  },
]

export const defaultTravelClass = [
  {
    label: 'Economy',
    value: 'Economy',
  },
  {
    label: 'Premium Economy',
    value: 'Premium Economy',
  },
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'First',
    value: 'First',
  },
]

export const defaultStopDurations = [
  {
    label: '-',
    value: 0,
  },
  {
    label: '15 min',
    value: 900,
  },
  {
    label: '30 min',
    value: 1800,
  },
  {
    label: '45 min',
    value: 2700,
  },
  {
    label: '1 hr',
    value: 3600,
  },
  {
    label: '1 hr 15 min',
    value: 4500,
  },
  {
    label: '1 hr 30 min',
    value: 5400,
  },
  {
    label: '1 hr 45 min',
    value: 6300,
  },
  {
    label: '2 hrs',
    value: 7200,
  },
  {
    label: '2 hrs 15 min',
    value: 8100,
  },
  {
    label: '2 hrs 30 min',
    value: 9000,
  },
  {
    label: '2 hrs 45 min',
    value: 9900,
  },
  {
    label: '3 hrs',
    value: 10800,
  },
  {
    label: '3 hrs 15 min',
    value: 11700,
  },
  {
    label: '3 hrs 30 min',
    value: 12600,
  },
  {
    label: '3 hrs 45 min',
    value: 13500,
  },
  {
    label: '4 hrs',
    value: 14400,
  },
  {
    label: '4 hrs 15 min',
    value: 15300,
  },
  {
    label: '4 hrs 30 min',
    value: 16200,
  },
  {
    label: '4 hrs 45 min',
    value: 17100,
  },
  {
    label: '5 hrs',
    value: 18000,
  },
  {
    label: '5 hrs 15 min',
    value: 18900,
  },
  {
    label: '5 hrs 30 min',
    value: 19800,
  },
  {
    label: '5 hrs 45 min',
    value: 20700,
  },
  {
    label: '6 hrs',
    value: 21600,
  },
  {
    label: '6 hrs 15 min',
    value: 22500,
  },
  {
    label: '6 hrs 30 min',
    value: 23400,
  },
  {
    label: '6 hrs 45 min',
    value: 24300,
  },
  {
    label: '7 hrs',
    value: 25200,
  },
  {
    label: '7 hrs 15 min',
    value: 26100,
  },
  {
    label: '7 hrs 30 min',
    value: 27000,
  },
  {
    label: '7 hrs 45 min',
    value: 27900,
  },
  {
    label: '8 hrs',
    value: 28800,
  },
  {
    label: '8 hrs 15 min',
    value: 29700,
  },
  {
    label: '8 hrs 30 min',
    value: 30600,
  },
  {
    label: '8 hrs 45 min',
    value: 31500,
  },
  {
    label: '9 hrs',
    value: 32400,
  },
  {
    label: '9 hrs 15 min',
    value: 33300,
  },
  {
    label: '9 hrs 30 min',
    value: 34200,
  },
  {
    label: '9 hrs 45 min',
    value: 35100,
  },
  {
    label: '10 hrs',
    value: 36000,
  },
  {
    label: '10 hrs 15 min',
    value: 36900,
  },
  {
    label: '10 hrs 30 min',
    value: 37800,
  },
  {
    label: '10 hrs 45 min',
    value: 38700,
  },
  {
    label: '11 hrs',
    value: 39600,
  },
  {
    label: '11 hrs 15 min',
    value: 40500,
  },
  {
    label: '11 hrs 30 min',
    value: 41400,
  },
  {
    label: '11 hrs 45 min',
    value: 42300,
  },
  {
    label: '12 hrs',
    value: 43200,
  },
]

export const hostAgencies = [
  { label: 'Avenue Two', value: 'Avenue Two' },
  { label: 'Travel', value: 'Travel' },
  { label: 'Avoya Travel', value: 'Avoya Travel' },
  { label: 'Departure Lounge', value: 'Departure Lounge' },
  { label: 'Embark Beyond', value: 'Embark Beyond' },
  { label: 'FORA', value: 'FORA' },
  { label: 'Gifted Travel', value: 'Gifted Travel' },
  { label: 'Network', value: 'Network' },
  { label: 'Global Travel Collection', value: 'Global Travel Collection' },
  { label: 'KHM Travel Group', value: 'KHM Travel Group' },
  { label: 'Montecito Village Travel', value: 'Montecito Village Travel' },
  { label: 'Nexion Travel Group', value: 'Nexion Travel Group' },
  { label: 'Outside Agents', value: 'Outside Agents' },
  { label: 'Protravel', value: 'Travel Edge' },
  { label: 'Travel Planners', value: 'Travel Planners' },
  { label: 'International', value: 'International' },
  { label: 'Travel Quest Network', value: 'Travel Quest Network' },
  { label: 'Uniglobe Travel Center', value: 'Uniglobe Travel Center' },
  { label: 'Tzell', value: 'Tzell' },
]

export const consortiums = [
  { label: 'Virtuoso', value: 'Virtuoso' },
  { label: 'Travel Leaders Group', value: 'Travel Leaders Group' },
  { label: 'Travel Experts', value: 'Travel Experts' },
  { label: 'Signature', value: 'Signature' },
  { label: 'Serandipians', value: 'Serandipians' },
  { label: 'Ensemble', value: 'Ensemble' },
]

export const getDefaultBookingDate = () =>
  moment()
    .add(1, 'd')
    .format('DD/MM/YYYY')
export const getDefaultBookingTime = () =>
  getRoundedTime(moment().add(1, 'd')).format('HH:mm')
export const minBookingDate = moment().toDate()
export const minBookingDateQuote = moment()
  .subtract(1, 'days')
  .toDate()
export const getProperDateFormat = date => {
  var arr = date.split('/')
  var newDate = `${arr[1]}/${arr[0]}/${arr[2]}`
  var string = moment(newDate).format('dddd, Do MMMM YYYY')
  if (string && string !== 'Invalid Date' && string !== 'Invalid date') {
    return string
  } else {
    var newDate = `${arr[1]}-${arr[0]}-${arr[2]}`
    string = moment(newDate).format('dddd, Do MMMM YYYY')
    if (string && string !== 'Invalid Date' && string !== 'Invalid date') {
      return string
    } else {
      return date
    }
  }
}
export const getDateFormatNew = date => {
  //shared booking date formating
  let mDate = moment(date, 'DD/MM/YYYY').format('ddd d MMM')
  if (mDate && mDate !== 'Invalid Date' && mDate !== 'Invalid date') {
    return mDate
  } else {
    //car rental date formating
    mDate = moment(date, 'YYYY-MM-DD').format('ddd d MMM')
    if (mDate && mDate !== 'Invalid Date' && mDate !== 'Invalid date') {
      return mDate
    } else {
      return date
    }
  }
}

export const getTimeFormatNew = time => {
  //car rental/shared booking time formating
  const mTime = moment(time, 'hh:mm A').format('HH:mm')
  if (mTime && (mTime !== 'Invalid Date' || mTime !== 'Invalid date')) {
    return mTime
  } else {
    return time
  }
}

export const setOptions = (from, value) => {
  let res = []
  for (let i = from; i <= value; i++) {
    res = [...res, { label: i, value: i }]
  }
  return res
}
export const locationPlaceholder = 'City, address, airport, hotel...'

export const WEBSOCKET_PREFIX = 'APP_WEBSOCKET'

export const defaultMembersBenefitsTypes = [
  {
    label: 'Fixed discount benefit',
    value: 'fixedDiscount',
  },
  {
    label: 'Percentage discount benefit',
    value: 'percentageDiscount',
  },
  {
    label: 'Service benefit',
    value: 'service',
  },
]
export const defaultMembersBenefitsFrequency = [
  {
    label: 'One-time use',
    value: 'oneTime',
  },
  {
    label: 'Every',
    value: 'every',
  },
]
export const defaultMembersBenefitsModes = [
  { label: 'Transfer', value: 'transfer' },
  { label: 'By the hour', value: 'chauffeurService' },
  { label: 'Car rental', value: 'carRental' },
]
export const defaultVipServices = [
  { label: 'Departure', value: 'departure' },
  { label: 'Arrival', value: 'arrival' },
]
export const defaultVipFlights = [
  { label: 'Domestic', value: 'domestic' },
  { label: 'International', value: 'international' },
  { label: 'Any', value: 'any' },
]
export const meetAndGreetArrivalServices = [
  'Meet & greet at the aircraft or air bridge',
  'Expedite immigration and customs',
  'Assist with luggage collection',
  'Escort to meet the chauffeur',
]
export const meetAndGreetArrivalServicesCarRental = [
  'Meet & greet at the aircraft or air bridge',
  'Expedite immigration and customs',
  'Assist with luggage collection',
  'Escort to the vehicle',
]
export const meetAndGreetDepartureServices = [
  'Meet & greet at the terminal entrance',
  'Assist with luggage and check-in',
  'Escort through security',
  'Escort to the lounge and shopping area',
  'Escort to the departure gate',
  'Assist with pre-boarding',
]
export const meetAndGreetTrainArrivalServices = [
  'Greeting at the train exit',
  'Assistance with luggage transportation',
  'Escort to meet the chauffeur',
]
export const meetAndGreetTrainDepartureServices = [
  'Meet & greet at the train station entrance',
  'Assistance with luggage transportation',
  'Escort during security checks (if any)',
  'Escort to the train seat',
]
export const defaultGreeterStatus = [
  { label: 'Show all', value: '' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]
export const limitedAvailabilityCountries = [
  'PT',
  'CR',
  'AW',
  'JM',
  'BS',
  'KY',
  'BB',
  'SX',
  'BL',
  'VG',
  'TC',
  'AG',
  'BZ',
  'PR',
  'CW',
  'DO',
  'AR',
  'PA',
  'BR',
  'IE',
  'IT',
  'CL',
  'AR',
]
export const limitedAvailabilityCities = [
  'Santa Barbara',
  'Monterey',
  'Carmel-by-the-Sea',
  'Napa',
  'Las Vegas',
  'Reno',
  'Truckee',
  'Canyon Point',
  'Moab',
  'Helena',
  'Bozeman',
  'Chicago',
  'Savannah',
  'Lihue',
  'Waimea',
  'Wailea',
  'Honolulu',
  'Kona',
  'Hilo',
  'Kapalei',
  'Kapalua',
  'Kihei',
  'Lahaina',
  'Kailua',
  'Megève',
  'Courchevel',
  "Val-d'Isère",
  "Val d'Isère",
  'Chamonix',
  'Les Allues',
  'Lyon',
  'Chambéry',
  'Grenoble',
  "Cortina d'Ampezzo",
  'Courmayeur',
  'San Cassiano',
  'Bolzano',
  'Kitzbuhel',
  'Saint Anton am Arlberg',
]
export const limitedAvailabilityStates = [
  { label: 'HI', value: 'Hawaii' },
  { label: 'CO', value: 'Colorado' },
]
export const ROLES_GROUP_SYSTEM = 'system'
export const ROLES_GROUP_COMPANY = 'company'

export const ROLE_SYSTEM_ADMIN = 'system_admin'
export const ROLE_SYSTEM_AGENT = 'system_agent'
export const ROLE_SYSTEM_MANAGER = 'system_manager'
export const ROLE_COMPANY_ADMIN = 'company_admin'
export const ROLE_COMPANY_USER = 'company_user'

export const userRoles = {
  [ROLES_GROUP_SYSTEM]: [
    { name: ROLE_SYSTEM_ADMIN, displayName: 'Super Admin' },
    { name: ROLE_SYSTEM_AGENT, displayName: 'Super Agent' },
    { name: ROLE_SYSTEM_MANAGER, displayName: 'Super Manager' },
  ],
  [ROLES_GROUP_COMPANY]: [
    { name: ROLE_COMPANY_ADMIN, displayName: 'Admin' },
    { name: ROLE_COMPANY_USER, displayName: 'User' },
  ],
}
export const isAdminSide = roles => {
  const role = userRoles[ROLES_GROUP_SYSTEM].some(ele =>
    roles.includes(ele.name)
  )
  return role
}
export const isSystemAdminOrManager = roles => {
  const role =
    roles.includes(ROLE_SYSTEM_ADMIN) || roles.includes(ROLE_SYSTEM_MANAGER)
  return role
}
export const isSystemAdmin = roles => {
  const role = roles.includes(ROLE_SYSTEM_ADMIN)
  return role
}
export const isSystemAgent = roles => {
  const role = roles.includes(ROLE_SYSTEM_AGENT)
  return role
}
export const isSystemManager = roles => {
  const role = roles.includes(ROLE_SYSTEM_MANAGER)
  return role
}
export const partnerHostLive = 'partner.rolzo.com'
export const partnerHostStaging = 'partner-staging.rolzo.com'
// export const partnerHostStaging = 'localhost'
export const defaultPromotionCodeTypes = [
  {
    label: 'Fixed discount benefit',
    value: 'fixedDiscount',
  },
  {
    label: 'Percentage discount benefit',
    value: 'percentageDiscount',
  },
  {
    label: 'Fixed discount/ commission benefit',
    value: 'fixedDiscComm',
  },
  {
    label: 'Percentage discount/ commission benefit',
    value: 'percentDiscComm',
  },
]
export const defaultPromotionCodeFrequency = [
  {
    label: 'One-time use',
    value: 'oneTime',
  },
  {
    label: 'Every',
    value: 'every',
  },
  {
    label: 'First booking only',
    value: 'firstBookingOnly',
  },
]
export const defaultPromotionCodeModes = [
  { label: 'All', value: 'allModes' },
  { label: 'Transfer', value: 'transfer' },
  { label: 'By the hour', value: 'chauffeurService' },
  { label: 'Car rental', value: 'carRental' },
  { label: 'VIP Meet & Greet', value: 'meetAndGreet' },
]
export const defaultTaxTerms = [
  { label: 'Sales tax', value: 'tax' },
  { label: 'VAT', value: 'vat' },
  { label: 'JCT', value: 'jct' },
  { label: 'GST', value: 'gst' },
]

export const defaultPayoutStatus = [
  { label: 'Show all', value: '' },
  { label: 'Paid', value: 'paid' },
  { label: 'Pending', value: 'not_paid' },
]

export const defaultApprovalStatus = [
  { label: 'Show all', value: '' },
  { label: 'Approved', value: 'true' },
  { label: 'Pending', value: 'false' },
]

export const defaultPayoutTerms = [
  { label: 'Weekly', value: 'payout_term_weekly' },
  { label: 'Monthly', value: 'payout_term_monthly' },
  { label: 'Credit card', value: 'payout_instant_by_card' },
  { label: 'PayPal', value: 'payout_instant_paypal' },
]

export const ultraLightMapStyle = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#333333',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
]
export const oceaniaCountryCodes = [
  'AS',
  'AU',
  'CX',
  'CK',
  'FJ',
  'PF',
  'GU',
  'KI',
  'MH',
  'FM',
  'NR',
  'NC',
  'NZ',
  'NU',
  'NF',
  'MP',
  'PW',
  'PG',
  'PN',
  'WS',
  'SB',
  'TL',
  'TK',
  'TO',
  'TV',
  'VU',
  'WF',
]

export const greeterAirPorts = [
  {
    placeId: 'ChIJ0SDcdKqglVQRPVwOOJN22ls',
    airport: 'Portland International Airport (PDX)',
  },
  {
    placeId: 'ChIJz4pBEgNpbIcRhMtM1vp1GNQ',
    airport: 'Denver International Airport (DEN)',
  },
  {
    placeId: 'ChIJN0na1RRw44kRRFEtH8OUkww',
    airport: 'Boston International Airport (BOS)',
  },
  {
    placeId: 'ChIJ_0T_mCp_bIcRapy1NbQ7WEk',
    airport: 'Denver International Airport (DEN)',
  },
  {
    placeId: 'ChIJTf2Yh-VnbIcRJgspfQUyca8',
    airport: 'Denver International Airport (DEN)',
  },
]

export const dispatchBannerCountries = ['US', 'FR', 'GB']
export const GrammarlyConfigs = {
  documentDialect: 'british',
  userFeedback: 'off',
  autocomplete: 'on',
  activation: 'immediate',
  suggestionCategories: {
    oxfordComma: 'on',
    prepositionAtTheEndOfSentence: 'on',
  },
}

export const defaultPayoutServiceStatus = [
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Completed', value: 'completed' },
  // { label: 'Quote Request', value: 'quote_in_progress' },
  { label: 'Quote received', value: 'quote_in_progress' },
  // { label: 'Request', value: 'is_request' },
  { label: 'Quote requested', value: 'is_request' },
  { label: 'Pending', value: 'in_review' },
  // { label: 'Payment declined', value: 'transaction_declined' },
  { label: 'Unavailable', value: 'unavailable' },
  {
    label: 'Cancellation requested',
    value: 'cancellation_requested_by_client',
  },
  { label: 'Cancelled', value: 'cancellation_accepted_by_agent' },
  { label: 'Cancelled', value: 'cancelled_by_agent' },
  { label: 'Quote', value: 'quote' },
  { label: 'Expired', value: 'quote_expired' },
  { label: 'Refunded', value: 'refunded' },
]

export const defaultActiveStatus = [
  { label: 'Show all', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]
export const defaultDescriptionType = [
  // { label: 'Link', value: 'link' },
  { label: 'Image', value: 'image' },
  { label: 'Title', value: 'title' },
  { label: 'Subtitle', value: 'subtitle' },
  { label: 'Description', value: 'description' },
  { label: 'Fleet carousel', value: 'fleet showcase' },
]
export const defaultShowCastOptions = [
  { label: 'Airport transfer', value: 'airport' },
  { label: 'By the hour', value: 'chauffeur' },
  { label: 'Car rental', value: 'rantal' },
]

export const defaultExtractionOptions = [
  // { label: 'All Bookings', value: 'allBookings' },
  { label: 'Client Bookings', value: 'clientBookings' },
  { label: 'Dispatched Bookings', value: 'dispatchedBookings' },
  { label: 'Gross Amount', value: 'grossAmount' },
  { label: 'Host Bookings', value: 'hostBookings' },
  { label: 'Searches', value: 'searches' },
]

export const defaultTaxStatus = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]
export const defaultTwentyFourHoursService = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

export const defaultCompanySizes = [
  { label: '1-10', value: '1-10' },
  { label: '10-100', value: '10-100' },
  { label: '100-1000', value: '100-1000' },
  { label: '1000+', value: '1000+' },
]
export const defaultIndustriesOptions = [
  { label: 'Travel', value: 'Travel' },
  { label: 'Lifestyle Management', value: 'Lifestyle Management' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Aviation', value: 'Aviation' },
  { label: 'Corporate', value: 'Corporate' },
  { label: 'Luxury Retail', value: 'Luxury Retail' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Events Services', value: 'Events Services' },
  { label: 'Other', value: 'Other' },
]

export const defaultVatFormats = {
  AT: /^U\d{8}$/, // Austria
  BE: /^(0?\d{9})$/, // Belgium, prefix with zero if 9 digits
  BG: /^\d{9,10}$/, // Bulgaria, 9 or 10 digits
  HR: /^\d{11}$/, // Croatia, 11 digits
  CY: /^\d{8}[A-Z]$/, // Republic of Cyprus, 8 digits followed by a letter
  CZ: /^\d{8,10}$/, // Czech Republic, 8, 9 or 10 digits
  DK: /^\d{8}$/, // Denmark, 8 digits
  EE: /^\d{9}$/, // Estonia, 9 digits
  FI: /^\d{8}$/, // Finland, 8 digits
  FR: /^(FR)?\d{11}$/, // France, 11 digits, may start with FR
  DE: /^\d{9}$/, // Germany, 9 digits
  EL: /^\d{9}$/, // Greece, 9 digits
  HU: /^\d{8}$/, // Hungary, 8 digits
  IE: /^\d{7}[A-Z]{1,2}$/, // Ireland, 7 digits followed by one or two letters
  IT: /^\d{11}$/, // Italy, 11 digits
  LV: /^\d{11}$/, // Latvia, 11 digits
  LT: /^(\d{9}|\d{12})$/, // Lithuania, 9 or 12 digits
  LU: /^\d{8}$/, // Luxembourg, 8 digits
  MT: /^\d{8}$/, // Malta, 8 digits
  NL: /^\d{9}B\d{2}$/, // Netherlands, 9 digits followed by 'B' and 2 digits
  PL: /^\d{10}$/, // Poland, 10 digits
  PT: /^\d{9}$/, // Portugal, 9 digits
  RO: /^\d{2,10}$/, // Romania, 2 to 10 digits
  SK: /^\d{10}$/, // Slovak Republic, 10 digits
  SI: /^\d{8}$/, // Slovenia, 8 digits
  ES: /^([A-Z]\d{8}|\d{8}[A-Z]|[A-Z]\d{7}[A-Z])$/, // Spain, 9 characters with letters in various positions
  SE: /^\d{12}$/, // Sweden, 12 digits
}

export const europeanCountriesCodes = [
  'AL',
  'AD',
  'AM',
  'AT',
  'AZ',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FO',
  'FI',
  'FR',
  'GE',
  'DE',
  'GI',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'KZ',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'MK',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SJ',
  'SE',
  'CH',
  'TR',
  'UA',
  'GB',
  'VA',
]
