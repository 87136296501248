import AppRoutes from './routes/App/AppRoutes'
import AuthRoutes from './routes/Auth/AuthRoutes'
import DriverRoutes from './routes/Driver/DriverRoutes'
import PartnerAuthRoutes from './routes/Driver/DriverApp/Auth/AuthRoutes'
import PaymentAuthRoutes from './routes/Payments/Auth/AuthRoutes'
import B2CAuthRoutes from './routes/B2C/Auth/AuthRoutes'
import IframeAuthRoutes from './routes/Iframe/Auth/AuthRoutes'
import { isMobile } from '../helpers/index'
import { partnerHostLive, partnerHostStaging } from '../constants'
const Host = window.location.hostname
const isMobileBrowser = isMobile()

var indexRoutes =
  Host === partnerHostLive || Host === partnerHostStaging
    ? isMobileBrowser
      ? [{ path: '/', component: DriverRoutes }]
      : [
          { path: '/auth', component: PartnerAuthRoutes },
          { path: '/', component: AppRoutes },
        ]
    : isMobileBrowser
    ? [
        { path: '/auth', component: AuthRoutes },
        { path: '/partner', component: DriverRoutes },
        { path: '/book', component: B2CAuthRoutes },
        { path: '/pay', component: PaymentAuthRoutes },
        { path: '/iframe-page', component: IframeAuthRoutes },
        { path: '/', component: AppRoutes },
      ]
    : [
        { path: '/auth', component: AuthRoutes },
        { path: '/partner/auth', component: PartnerAuthRoutes },
        { path: '/book', component: B2CAuthRoutes },
        { path: '/pay', component: PaymentAuthRoutes },
        { path: '/iframe-page', component: IframeAuthRoutes },
        { path: '/partner', component: AppRoutes },
        { path: '/', component: AppRoutes },
      ]

export default indexRoutes
